import React from 'react';
import {Link} from 'react-router-dom';
import IMask from 'imask';

import {contact_info} from '../assets/options';

export default class Header extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            contact_info: {}
        }
    }
    async componentDidMount(){
        var masked = IMask.createMask({
            mask: '+1 (000) 000 0000',
          });
        var info = await contact_info();
        info.telephone_masked = masked.resolve(info.telephone.toString());

        this.setState({contact_info: info});
    }
    setMenu(){
        if(this.menuBtn.classList.contains('active')){
            this.menuBtn.classList.remove('active');
        }else{
            this.menuBtn.classList.add('active');
        }
    }
    render(){
        return(
            <header>
                <div className="left">
                    <div className="logo"><Link to="/" onClick={(e)=>{this.menuBtn.classList.remove('active')}}><img src={require('../assets/img/logo.png')} alt="Logo of Lending Break"/></Link></div>
                </div>
                <div className="right">
                    <div ref={el=>this.menuBtn = el} className="menuBtn" onClick={(e)=>this.setMenu()}>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <nav>
                        <ul>
                            <li><Link to="/offers" onClick={(e)=>this.setMenu()}>Offers</Link></li>
                            <li><Link to="/about-us" onClick={(e)=>this.setMenu()}>About us</Link></li>
                            <li><Link to="/blog" onClick={(e)=>this.setMenu()}>Blog</Link></li>
                            <li><Link to="/contact-us" onClick={(e)=>this.setMenu()}>Contact us</Link></li>
                            <li className="phone_number"><a href={"tel:+" + this.state.contact_info.telephone}>{this.state.contact_info.telephone_masked}</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}
import React from 'react';

export default class DiscriminationAndHarassment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Discrimination and Harassment</h1>

                    <div className="text">
                        <p>This policy was developed to help ensure the company's equal employment opportunity
                            statement and compliance with all applicable laws prohibiting unlawful and unfair
                            treatment, discrimination or harassment.</p>

                        <p>
                            Incursus is committed to provide a positive environment where everyone can be a
                            successful contributor. We would like to ensure equal opportunity and fair treatment to
                            all individuals on the basis of merit, without discrimination because of race, color,
                            religion, national origin, gender, sexual orientation, age, disability, veteran status or
                            other characteristic protected by law. Harassment is unwelcome conduct (physical,
                            verbal or non-verbal) based on an individual’s protected status that creates an
                            environment that is intimidating, hostile, or abusive, or a situation where enduring such
                            conduct is a condition of employment. The company does not tolerate discrimination or
                            unlawful harassment of employees by managers, supervisors, coworkers, or nonemployees at work. Similarly, the company prohibits harassment in any form, whether
                            physical or verbal and whether committed by supervisors, non-supervisory personnel or
                            non-employees with whom employees have a business, service, or professional
                            relationship. Employees who are found to have violated this policy are subject to
                            discipline, including but not limited to: coaching, training, a verbal warning, a written
                            warning, impact to performance ratings, impact to compensation, or termination of
                            employment.
                        </p>

                        <h4>Disability Discrimination</h4>
                        <p>
                            The company will provide reasonable facilities and accommodations as provided by law
                            for the identified physical or mental disabilities of otherwise eligible and qualified
                            applicants and employees with disabilities unless there would be unreasonable
                            difficulties on the Company. Any applicant or employee with a disability who needs
                            accommodation to fulfil the essential functions of the job should contact his/her
                            manager or the company’s Support Team at 8664462877, 866-4Incursus or e-mails us
                            at <a href="mailto:info@incursus.co"><mark>info@incursus.co</mark></a> or <a href="mailto:support@desktime.com"><mark>support@desktime.com</mark></a> to request an accommodation.
                            Employees are required to entirely collaborate in an interactive manner with the
                            company during the accommodation process.
                        </p>

                        <h4>Religious Discrimination</h4>
                        <p>
                            Unless it would be an undue hardship on the employer's operation of its business, an
                            employer must reasonably accommodate an employee's religious beliefs or practices
                            according to CSAA IG's policy. This applies not only to schedule changes or leave for
                            religious observances, but also to such things as dress or grooming practices that an
                            employee has for religious reasons. When an employee or applicant needs a dress or
                            grooming accommodation for religious reasons, he should contact and notify to Human
                            Resources Consultant (HRC) that he needs such an accommodation for religious
                            reasons. If the employer reasonably needs more information, the employer and the
                            employee should engage in an interactive process to discuss the request. If it would not
                            pose an undue hardship, the employer must grant the accommodation. Employees who
                            try to uphold their religious values, traditions and practices have a duty to do their part
                            to help overcome conflicts between job duties and religious needs.
                        </p>

                        <h4>Sexual Harassment</h4>
                        <p>
                            Sexual harassment in the workplace is a form of sex discrimination which negatively
                            affects the working environment, undermines gender equality at work, creates unfair
                            practices in employment, and adversely impacts the dignity and well-being of
                            employees. It creates psychological anxiety and stress for victims and if ignored, can
                            result in high costs for companies through loss of productivity, low worker morale,
                            absenteeism, and staff turnover.
                            Sexual harassment means any unwanted, unwelcome or uninvited behavior of a sexual
                            nature which a reasonable person in the circumstances would find humiliating,
                            intimidating or offensive. This definition includes many forms of offensive behavior and
                            includes harassment of a person of the same or opposite sex as the harasser. Incursus
                            prohibits sexual harassment, and will take appropriate and immediate action in
                            response to complaints or knowledge of violations of this policy.
                            Sexual harassment in the workplace is unwelcome conduct (physical, verbal or nonverbal) of a sexual nature and includes, but is not limited to, conduct such as the
                            following: <br /><br />

                            <ul>
                                <li>Unwanted sexual advances; </li>
                                <li>Offering employment benefits in exchange for sexual favors;</li>
                                <li>Making or threatening reprisals after a negative response to sexual advances; </li>
                                <li>Visual conduct, e.g., leering, making sexual gestures, displaying of sexually
                                    suggestive objects or pictures, cartoons or posters;</li>
                                <li>Verbal conduct, e.g., making or using derogatory comments, epithets, slurs or
                                    jokes;</li>
                                <li>Verbal sexual advances or propositions;</li>
                                <li>
                                    Verbal abuse of a sexual nature, graphic verbal commentaries about an
                                    individual's body, sexually degrading words used to describe an individual,
                                    suggestive or obscene letters, notes or invitations; or
                                </li>
                                <li>Physical conduct, e.g., touching, assault, impeding or blocking movements.</li>
                                <li>
                                    Preferential treatment or favoritism toward one subordinate over another by
                                    virtue of his/her involvement in an intimate relationship with the
                                    supervisor/manager.
                                </li>
                            </ul>
                        </p>

                        <h4>Supervisor/Manager-Subordinate Relationships</h4>
                        <p>
                            This policy establishes boundaries as to how relationships are conducted during
                            working hours and within the working environment.The company forbids a supervisor or
                            manager from directly or indirectly supervising an employee with whom he or she is
                            married or involved in any other familial, romantic, intimate, or dating relationship. Such
                            a relationship between a supervisor or manager and his or her subordinate presents
                            significant risk for the occurrence or appearance of sexual harassment. Incursus
                            strongly believes that a work environment where employees maintain clear boundaries
                            between employee personal and business interactions is necessary for effective
                            business operations.
                        </p>

                        <p>
                            Romantic relationships between employees where one individual has influence or
                            control over the other's conditions of employment are inappropriate. These
                            relationships, even if consensual, may ultimately result in conflict or difficulties in the
                            workplace. If such relationship exists or develops between two people, both employees
                            have a responsibility to promptly disclose the relationship to senior management and a
                            Human Resources Consultant (HRC). The disclosure will help the company to timely
                            decide if any conflict of interest exists. If any of the above or similar forbidden
                            circumstances should arise, the employees may be separated by reassignment or
                            change their position, if available. A termination may be required under some
                            conditions.
                        </p>

                        <h4>Reporting Discrimination or Harassment</h4>
                        <p>
                            Knowing how to handle harassment complaints properly helps everyone involved feel
                            safer and happier. Incursus offers multiple avenues through which you can report
                            harassment and submit your complaints. If you have any complaints about
                            discrimination or harassment, including work-related harassment, by any employee or
                            any other person, report such conduct immediately to your supervisor, manager, or
                            Human Resources Consultant (HRC).
                            <br /><br />
                            There is no need for the employee to report harassment to his or her management first,
                            but may report it to a Human Resources Consultant (HRC) or through the company's toll
                            free hotline at <mark>8664462877</mark>. Supervisors and managers who receive harassment
                            complaints or witness actions that may be perceived as discrimination or harassment
                            must promptly inform HRC.

                            <br /><br />
                            Allegations and complaints of discrimination or harassment will be reviewed
                            immediately and in depth. Complaints will be kept confidential to the fullest extent
                            possible, and research and inquiries will be carried out impartially, reported and
                            monitored by trained staff. If, at the end of the research and investigation, misconduct is
                            found, then effective remedial action will be taken.

                            <br /><br />
                            Many harassment victims fear retaliation from their harasser, so you should address this
                            fear as you deal with the allegation. It’s important to ensure the reporter that we will
                            maintain confidentiality as much as possible. Our company will not tolerate retaliation
                            for making a complaint of harassment or cooperating in an investigation. It is illegal to
                            retaliate against someone for opposing harassment or who takes part in inquiries into
                            harassment.

                            <br /><br />
                            You may also contact the Equal Employment Opportunity Commission (EEOC) at (800)
                            669-4000 or the nearest EEOC office, or <mark>our EthicsLine at _______</mark>(24 hours per day,
                            365 days per year).

                        </p>

                        <h4>Non-retaliation</h4>
                        <p>
                            Each of us should expect, and has a responsibility to uphold, a workplace and culture
                            that are free of harassment, discrimination, misconduct, abusive conduct, and
                            retaliation. The company forbids retaliation for reporting conduct that violates or
                            potentially violates discrimination and harassment laws, or for cooperating in an
                            investigation. Managers have a responsibility to create, uphold, and promote a safe, respectful, and inclusive work environment. Managers are required to promptly report
                            any violation or suspected violation of this policy to HRC. Like managers, all employees
                            of the company are required to comply with the standards of conduct set forth in this
                            policy. Each employee of the Company has an obligation to comply with this policy and
                            is expected to foster a workplace culture that is free of harassment, discrimination,
                            abusive conduct and retaliation. If you observe or become aware of such improper
                            conduct that might violate this policy, you should immediately report it
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';

export default class TermsOfUse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>TERMS OF USE</h1>

                    <div className="text">
                      <p>
                        This page describes the terms and conditions that govern your use of Incursus (the “Site”).
                        <br /><br />
                        Please see Incursus’ Privacy Policy for information about our data collection practices.
                        <br /><br />
                        For more information, please Contact Us
                      </p>

                      <p>
                        <strong>1.</strong> General
                      </p>

                      <p>
                      <strong>1.1</strong> By choosing to use the Site, you are agreeing to abide by all of the terms and conditions of this Terms of Use between you and Incursus.
                      </p>

                      <p>
                      <strong>2.</strong> Content on the Site and in Publications
                      </p>

                      <p>
                      <strong>2.1</strong> All materials published on the Site (including, but not limited to news articles, photographs, images, illustrations, audio clips and video clips, also known as the “Content”) are protected by copyright and owned or controlled by the Incursus or the party credited as the provider of the Content. You shall abide by all additional copyright notices, information, or restrictions contained in any Content accessed through the Site.
                      </p>

                      <p>
                      <strong>2.3</strong> You may download or copy the Content and other downloadable items displayed on the Site for personal use only, if you maintain all copyright and other notices contained therein.
                      </p>

                      <p>
                      <strong>3.</strong> User-generated content, comments, and submissions
                      </p>

                      <p>
                      <strong>3.1</strong> You are solely responsible for the content of your Submissions to the Site. You shall not upload to distribute or otherwise publish to the Site, any libelous, defamatory, obscene, pornographic, abusive, or otherwise illegal material.
                      </p>

                      <p>
                      You agree that you will not threaten or verbally abuse any Incursus employees or other users of the Site, use defamatory language, or deliberately disrupt discussions with repetitive messages, meaningless messages or “spam.”
                      </p>
                      
                      <p>
                      You agree not to use language that abuses or discriminates based on race, religion, nationality, gender, sexual preference, age, region, disability, etc. Hate speech of any kind is grounds for immediate and permanent suspension of access to all or part of the Site.
                      </p>

                      <p>
                      <strong>3.2</strong> Without the express approval of Incursus, you may not distribute or otherwise publish any material containing any solicitation of funds, advertising or solicitation for goods or services through the Site.
                      </p>

                      <p>
                      <strong>3.3</strong> You acknowledge that any submissions you make to the Site (i.e., user-generated content including but not limited to: comments, forum messages, reviews, text, video, audio and photographs, as well as computer code and applications) (each, a “Submission”) may be edited, removed, modiﬁed, published, transmitted and displayed by Incursus and you waive any rights you may have in having the material altered or changed in a manner not agreeable to you.
                      </p>

                      <p>
                      <strong>3.4</strong> You grant to Incursus a perpetual, nonexclusive, world-wide, royalty free, sub-licensable license to Content you submit to the Site, which includes without limitation, the right for Incursus, its affiliates, successors, assigns or any third party it so designates, to use, copy, transmit, excerpt, publish, distribute, publicly display, publicly perform, create derivative works of, host, index, cache, tag, encode, modify and adapt (including without limitation the right to adapt to streaming, downloading, broadcast, mobile, digital, thumbnail, scanning or other technologies) in any form or media now known or hereinafter developed, any submission posted by you on or to the Site.
                      </p>

                      <p>
                      <strong>4.</strong> Use of the Site; registration and security
                      </p>

                      <p>
                      <strong>4.1</strong> You may not access parts of the Site to which you are not authorized.
                      </p>
                      
                      <p>
                      <strong>4.2</strong> The Site may contain links to other related World Wide Web Internet sites, resources, and advertisers. Since Incursus is not responsible for the availability of these outside resources, or their contents, you should direct any concerns regarding any external link to such sites.
                      </p>

                      <p>
                      <strong>4.3</strong> This site is not intended for Children.  You must be 11 years or older to request information or to be contacted by Incursus.
                      </p>
                      
                      <p>For more information, please read our Privacy Policy.</p>

                      <p><strong>5.</strong> Representations and Warranties</p>
                      
                      <p>
                      Incursus does not represent or endorse the accuracy or reliability of any advice, opinion, statement, or other information displayed, uploaded, or distributed through the Site by any third party, user, information provider or other person or entity except for itself. You acknowledge that any reliance upon any such opinion, advice, statement, memorandum, or information shall be at your sole risk.
                      </p>

                      <p>
                      THE SITE IS DISTRIBUTED ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. YOU HEREBY ACKNOWLEDGE THAT USE OF THE SITE IS AT YOUR SOLE RISK.
                      </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import Select from 'react-select';
import IMask from 'imask'

import {CheckInput} from '../assets/plugins/functions';
import  {options, token, host} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class Offers extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            showResults: false,
            result: {},
            loading: false
        };

        this.data = {
            debt_amount: '',
            credit_score: '',
            annual_income: '',
            first_name: '',
            last_name: '',
            business_owner: '',
            birthday: '',
            phone_number: '',
            email: '',
            street: '',
            apt: '',
            city: '',
            state: '',
            zip_code: '',
        };
    }
    componentDidMount(){
        this._isMounted = true;
        window.scrollTo(0, 0);
        

        if(!this.state.showResults){
            var phone_number_mask = IMask(this.phone_number.querySelector('input'), {
                mask: '+{1} (000) 000 00000000',
                lazy: false,
                placeholderChar: ' ',
                autofix: true,
              });
    
            phone_number_mask.on("accept", function () {
                this.data.phone_number = phone_number_mask.unmaskedValue;
                
                // if(phone_number_mask.value.replace(/ /g, "").length < 14){
                //     this.phone_number.classList.add('wrong');
                // }else{
                //     this.phone_number.classList.remove('wrong');
                // }
            }.bind(this));
    
            var birthday_mask = IMask(this.birthday.querySelector('input'), {
                mask: Date,
                pattern: 'd{/}`m{/}`y',
                lazy: false,
                placeholderChar: ' ',
                autofix: true,
                blocks: {
                    d: {
                      mask: IMask.MaskedRange,
                      from: 1,
                      to: 31,
                      maxLength: 2,
                    },
                    m: {
                      mask: IMask.MaskedRange,
                      from: 1,
                      to: 12,
                      maxLength: 2,
                    },
                    y: {
                      mask: '00000',
                      from: 1900,
                      to: 9999,
                      maxLength: 4,
                    }
                  }
              });
    
              birthday_mask.on("accept", function () {
                this.data.birthday = birthday_mask.value;
    
                if(birthday_mask.value.replace(/ /g, "").length < 10){
                    this.birthday.classList.add('wrong');
                }else{
                    this.birthday.classList.remove('wrong');
                }
            }.bind(this));
        }
    }
    componentWillReceiveProps(){
        window.scrollTo(0, 0);

        this.setState({
            showResults: false,
        })
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    onChangeSelect(e, name){
        this.data[name] = e.value;

        this[name].children[0].value = e.label;

        this[name].classList.remove('wrong');
    }
    onChange(input){
        this.data[input.name] = new CheckInput().checkOnChange(this[input.name]);
    }
    onSubmit(e){
        e.preventDefault();

        let verify = new CheckInput().checkOnSubmit(this, this.data);

        if(verify){
            this.setState({
                loading: true
            });

            axios.post(host + '/api/offers/seeMyOffers', this.data, {headers: {'authorization': token}})
            .then((res)  => {
                if (res.status === 200) {

                    // Object.keys(this.data).forEach(key=>{
                    //     this.data[key] = '';
                    // });

                    let {data} = res;

                    if(data.result.errors && data.result.errors.length > 0){
                        if(this._isMounted)
                            this.setState({
                                result: {error: data.result.errors[0].error[0]},
                                showResults: true,
                                loading: false
                            });
                    }else{
                        if(this._isMounted)
                            this.setState({
                                result: {success: true},
                                showResults: true,
                                loading: false
                            });
                    }

                    // window.scrollTo(0, 0);
                } else {
                  const error = new Error(res.data.error);
                  throw error;
                }
              })
            .catch(err => {
                console.error(err.response?err.response.data.error:err);
            });
        }
    }
    loadData(){
        let arr = [[1], [1], [1,1,1]];

        return arr.map((items, i_items)=>{
            return <div key={i_items} className="row">
                <div className="info">See your Pre-Approved debt resolution offers to consolidate $100,000</div>
                {items.map((item, i_item)=>{
                    let matched = false;

                    if(i_items === 1 && i_item === 0) matched = true;

                    return <div key={i_item + i_items} className="tr">
                        <div className="td logo">
                            <img src={require('../assets/img/national_debt_logo.png')} alt=""/>
                        </div>
                        <div className="td">
                            <div className="value">0% Interest</div>
                            <div className="desc">Includes $0 upfront fees</div>
                        </div>
                        <div className="td">
                            <div className="value">$1,458</div>
                            <div className="desc">Payment for 48 months</div>
                        </div>
                        <div className="td">
                            <div className="value">$100,000</div>
                            <div className="desc">Amount</div>
                        </div>
                        <div className="td">
                            <div className="value">$70,000</div>
                            <div className="desc">Total cost <span></span></div>
                        </div>
                        <div className="td button">
                            <button className={`standart ${matched?'matched':''}`}>{matched?'matched':'select'}</button>
                        </div>
                    </div>})}
            </div>
        });
    }
    render () {
        return (
            <main id="offers">

                <MetaTags>
                    <title>Improve Your Existing Credit Score</title>
                    <meta name="description" content="Utilize a credit line, personal loan, cash-out mortgage refinancing, or debt resolution. Reduce your interest rate or nullify it completely with our help." />
                </MetaTags>

                {this.props.children}
                <div className="data">
                    <div className="left">
                        <h2>Now let's get you the best debt consolidation available</h2>
                        <div className="img">
                            <img src={require('../assets/img/illust_9.png')} alt=""/>
                        </div>
                    </div>
                    <form className="right" methof="POST" onSubmit={(e)=>this.onSubmit(e)}>
                        <div className="debt">
                            <h3>Debt Details</h3>
                            <label ref={el=>this.debt_amount = el}>
                                Debt Amount, $<span></span>
                                <input type="number" name="debt_amount" placeholder="Debt Amount" onChange={(e)=>this.onChange(e.target)} required/>
                                <div className="i">Lorem IpsumLorem IpsumLorem IpsumLorem Ipsum</div>
                            </label>
                            <label ref={el=>this.credit_score = el}>
                                <input type="hidden" name="credit_score" hidden required/>
                                Credit Score
                                <Select
                                    isSearchable={false}
                                    onChange={(e) => this.onChangeSelect(e, 'credit_score')}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={165}
                                    placeholder="Select"
                                    options={options.credit_score}
                                    className="select"
                                />
                            </label>
                            <label ref={el=>this.annual_income = el}>
                                Annual Income, $
                                <input type="number" name="annual_income" placeholder="Annual Income" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                        </div>
                        <div className="personal">
                            <h3>Personal Details</h3>
                            <label ref={el=>this.first_name = el}>
                                First Name
                                <input type="text" name="first_name" placeholder="First Name" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label ref={el=>this.last_name = el}>
                                Last Name
                                <input type="text" name="last_name" placeholder="Last Name" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label ref={el=>this.birthday = el}>
                                Date of Birth (MM/DD/YYYY)
                                <input type="text" name="birthday" placeholder="Last Name" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label ref={el=>this.business_owner = el}>
                                <input type="hidden" name="business_owner" hidden required/>
                                Are you a business owner?
                                <Select
                                    isSearchable={false}
                                    onChange={(e) => this.onChangeSelect(e, 'business_owner')}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={165}
                                    placeholder="Select"
                                    options={options.business_owner}
                                    className="select"
                                />
                            </label>
                            <label ref={el=>this.phone_number = el}>
                                Mobile Phone Number
                                <input type="text" name="phone_number" placeholder="Mobile Phone Number" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label ref={el=>this.email = el}>
                                Email Address
                                <input type="email" name="email" placeholder="Email Address" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label ref={el=>this.street = el}>
                                Street Address
                                <input type="text" name="street" placeholder="Street Address" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label className="short alon" ref={el=>this.apt = el}>
                                Suite/Apt
                                <input type="text" name="apt" placeholder="Apt #" onChange={(e)=>this.onChange(e.target)}/>
                            </label>
                            <label ref={el=>this.city = el}>
                                City
                                <input type="text" name="city" placeholder="City" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <label className="short" ref={el=>this.state = el}>
                                <input type="hidden" name="state" hidden required/>
                                State
                                <Select
                                    isSearchable={true}
                                    onChange={(e) => this.onChangeSelect(e, 'state')}
                                    closeMenuOnSelect={true}
                                    maxMenuHeight={165}
                                    placeholder="State"
                                    options={options.state}
                                    className="select"
                                />
                            </label>
                            <label className="short" ref={el=>this.zip_code = el}>
                                Zip Code
                                <input type="number" name="zip_code" placeholder="Zip Code" onChange={(e)=>this.onChange(e.target)} required/>
                            </label>
                            <button type="submit" className="standart" onClick={(e)=>{if(!this.state.loading)this.onSubmit(e)}}>see my offers</button>
                            <p>By clicking ‘SEE MY OFFERS’ I consent to a Soft Inquiry, agree to Lending Break's <Link to="/terms-of-service">Terms of Service</Link> and <Link to="/privacy-policy">Privacy Policy</Link> and authorize Lending Break and these Debt Consolidation Partners to contact me by phone, email or SMS text, which may include automated dialing, messaging and prerecorded voice technology.</p>
                        </div>
                    </form>
                </div>
                {this.state.showResults?<div className="msg">
                    {this.state.result.error?<div className="error">
                        <div className="title">Something went wrong</div>
                        <div className="text">{this.state.result.error}</div>
                        <button className="standart" onClick={()=>this.setState({showResults: false, result: {}})}>ok</button>
                    </div>:null}
                    {this.state.result.success?<div>
                        <div className="title">Thank for siging up,</div>
                        <div className="text">a consultant will reach out to you soon</div>
                        <button className="standart" onClick={()=>this.setState({showResults: false, result: {}})}>ok</button>
                    </div>:null}
                </div>:null}
                {/* {this.state.showResults?<div className="results">
                    <div className="greeding">
                        David, you've been matched with the smartest debt consolidation offers available.
                    </div>
                    <p>Your matched Debt Consolidation Partners will be calling you shortly. You can also click the select buttons below to see additional options.</p>
                    <div className="container">
                        {this.loadData()}
                    </div>
                </div>:null} */}
            </main>
        );
    }
}
import React from 'react';
import IMask from 'imask';

import {contact_info} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class AboutUs extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            contact_info: {}
        }
    }
    async componentDidMount(){
        window.scrollTo(0, 0);

        var masked = IMask.createMask({
            mask: '+1 (000) 000 0000',
          });
        var info = await contact_info();
        info.telephone_masked = masked.resolve(info.telephone.toString());

        this.setState({contact_info: info});
    }
    render(){
        return(
            <main id="about_us">
                <MetaTags>
                    <title>Improve Your Credit with LendingBreak</title>
                    <meta name="description" content="We will quickly match you with the best debt consolidation solution to fit your needs!" />
                </MetaTags>

                {this.props.children}
                <div className="top">
                    <div className="text">
                        <h1>About us</h1>
                        <p>We will quickly match you with the best debt consolidation solution to fit your needs!</p>
                    </div>
                    <div className="img">
                        <img src={require('../assets/img/illust_4.png')} alt="debt consolidation company"/>
                    </div>
                </div>
                <div className="debthunch">
                    <h3>Debt Consolidation: What Is It?</h3>
                    <div className="text">
                        <p>Debt consolidation takes existing debt – whether it comes from student loans, collection accounts, medical bills, business loans, credit lines, personal loans, or credit cards – and puts them all into a single monthly payment. Interest rates come with this single payment will be a lot lower than what you are currently paying, allowing you to reduce what you owe much faster. The amount of interest you pay in total each month will also be significantly lowered.</p>
                    </div>
                </div>
                {/* <div className="clip">
                    <div className="video">
                        <video controls>
                            <source src={require('../assets/video/1.mp4')} type="video/mp4" />
                        </video>
                    </div>
                    <h3>Watch this short clip about us</h3>
                </div> */}
                <div className="working">
                    <h3>Debt Consolidation: How Does It Work?</h3>
                    <div className="text">
                        <p>A quartet of debt consolidation principles must be looked over and examined first. Each of these options will allow you to take all of your debts and put them into one account to pay off each month.</p>
                        <div className="title">COMMON DEBT CONSOLIDATION OPTIONS</div>
                        <ul>
                            <li>Personal Loan</li>
                            <li>Line of Credit</li>
                            <li>Mortgage Refinance</li>
                            <li>Debt Resolution</li>
                        </ul>
                    </div>
                </div>
                <div className="whyUse">
                    <div className="text">
                        <ul>
                            <li>
                                <div className="title">Interest Rate Reduction</div>
                                <p>Utilizing a credit line, personal loan, cash-out mortgage refinancing, or a debt resolution will help you reduce your interest rate or nullify it completely, based on your current situation. The purpose of these alternatives is to help you pay out debts much faster. You'll also pay a lot less interest in the process, helping you allocate money saved from not paying interest towards your actual debt.</p>
                            </li>
                            <li>
                                <div className="title">Repayment Schedule</div>
                                <p>All solutions for debt consolidation will come with a payment timetable you should follow. You'll be notified of the exact date you could potentially become debt-free, assuming the repayment schedule is followed.</p>
                            </li>
                            <li>
                                <div className="title">Improve Your Credit</div>
                                <p>Solutions for debt consolidation can improve your existing credit score, not just reduce the amount of money you owe. Best of all, you can pay back what you owe much faster.</p>
                            </li>
                        </ul>
                    </div>
                    <h3>Why should you use consider debt consolidation?</h3>
                </div>
            </main>
        );
    }
}
import React from 'react';
import IMask from 'imask';

import {contact_info} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class TermsOfService extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            contact_info: {}
        }
    }
    async componentDidMount(){
        var masked = IMask.createMask({
            mask: '+1 (000) 000 0000',
          });
        var info = await contact_info();
        info.telephone_masked = masked.resolve(info.telephone.toString());

        this.setState({contact_info: info});

        window.scrollTo(0, 0);
    }
    render(){
        return(
            <main id="privacy_policy">
                <MetaTags>
                    <title>Terms of Service | LendingBreak</title>
                    <meta name="description" content="-" />
                </MetaTags>

                {this.props.children}
                <div>
                    <h1>Terms of Service</h1>
                    <div className="text">
                        <p>This Privacy Policy describes how Lending Break collects, uses, shares, and protects your information. This Privacy Policy applies to all information collected by Lending Break through this website and our services. Your use of this website and our services, and any disputes arising from it, is subject to this Privacy Policy as well as our Terms of Service and all of the dispute resolution provisions contained therein.</p>
                        <p>This Privacy Policy is intended to only cover our privacy practices. We are not responsible for the privacy practices of any websites you may access from our site (such as the site of a debt consolidation company with whom you are matched). When you leave Lending Break website, we encourage you to carefully review the privacy policies on all other websites you visit.</p>

                        <h4>Information We Collect​</h4>
                        <p>We may collect information about you such as your name, mailing address, email address and phone number (“Personal Information”).<br/>
                            We also collect information such as demographic data and data about your activity on our website (“Non-Personal Information”) and information when you apply for debt consolidation with Lending Break partners or affiliates. In connection with an application or transaction, you might enter additional information, which could include your social security number, date of birth, financial information, email addresses, phone numbers and other personal information.</p>

                        <h4>Other Sources</h4>
                        <p>We may also collect information about you from third parties. Such information may include, without limitation, information to supplement or confirm the information you’ve provided and other demographic information about you.</p>

                        <h4>How We Collect Information From You</h4>
                        <p>We may collect information from you when you use our services. The information we collect from you may vary depending on the product or service you are seeking. We may also collect information from you if you otherwise contact us.</p>

                        <h4>Browser Cookies</h4>
                        <p>Browser cookies are pieces of data stored on your computer that allow us to identify your browser software the next time you visit our website, and to provide us with information about pages viewed and how you navigated to our site (e.g., web search or link). Cookies can be used for website functionality, security, marketing, analytics, and ad operations.</p>
                        <p>In addition to using our own cookies, our website may also use cookies belonging to third-party entities involved in analytics, ad operations and third-party hosted solutions. Some of these cookies allow third parties to identify your browser software when you visit other sites. For example, some of these cookies are used for the purpose of serving behaviorally targeted advertising.</p>
                        <p>We use both session ID cookies and persistent cookies. For the session ID cookie, once a user closes his or her browser, the cookie simply expires. A persistent cookie, however, persists across browsing sessions, and is set to expire at a future date, which could be days, months, or years in the future. Persistent cookies can be removed by consulting the “Help” features in your browser. Like most standard website servers, we use log files to collect Non-Personal Information. This information includes, among other things, Internet Protocol (IP) addresses, browser type, device information, Internet Service Provider (ISP), referring/exit pages, operating system, date/time stamp and number of clicks. We may also collect information about your browser and device, such as installed fonts, screen size, and screen resolution, which, together with other information, my uniquely distinguish your browser software or device from others.</p>
                    
                        <h4>Flash Cookies​</h4>
                        <p>“Flash” cookies, also known as “local shared objects,” are small pieces of data stored on your computer that can serve the same or similar purposes as browser cookies (as well as some additional purposes relating to the functioning of Adobe Flash technology which is used to serve rich content such as videos and animation). “Flash” cookies, however, are not associated with browser software, but rather with Adobe software, such as Flash Player. “Flash” cookies can be removed by consulting guidance on the Adobe website, at adobe.com.</p>

                        <h4>Other Data Collection Technologies</h4>
                        <p>Web Beacons. We may use web beacons in the form of extremely small “.gif” files, which are electronic images that allow for the collection of information about your interactions on our sites. Web beacons may be used, for example, to place advertisements, to understand website traffic patterns and the number of visitors to our website, and to measure the effectiveness of advertisements or our website.<br/>
                            Social Network and Other Widgets. Our website may include social network or other third-party plug-ins and widgets that may provide information to their associated social networks or third-parties about the page you visit, even if you do not click on or otherwise interact with the plug-in or widget and regardless of whether you have an account or other relationship with these social networks and third-parties. Information is transmitted from your browser and may include an identifier assigned by the social network or third party, information about your browser type, operating system, device type, IP address and the URL of the web page where the plug-in or widget appears. If you use social network tools or visit social networking sites, you should read their privacy disclosures, to learn what information they collect, use, and share.</p>
                    
                        <h4>How Lending Break Uses Information​</h4>
                        <p>We may use your information to perform, without limitation, the following tasks:</p>
                        <ul>
                            <li>To connect you with debt consolidation companies;</li>
                            <li>To provide you with additional products and services;</li>
                            <li>To communicate with you about products or services that may be of interest to you;</li>
                            <li>To provide you with customer support;</li>
                            <li>To research and analyze your use or interest in our products and services;</li>
                            <li>To develop and display content and services tailored to your interests;</li>
                            <li>To improve our website, products and services; or</li>
                            <li>To measure the effectiveness of our advertising, content, and other activities.</li>
                        </ul>

                        <h4>Contact Us​</h4>
                        <p>If you have any questions about our Privacy Policy or our privacy practices in general, please contact us by mail or email.</p>
                    
                        {/* <h4>Corporate Information​</h4>
                        <p>Lending Break<br/>
                            {this.state.contact_info.address}<br/>
                            <a href={"mailto:" + this.state.contact_info.email}>{this.state.contact_info.email}</a><br/>
                            Date of Last Revision: July 4, 2019</p> */}
                    </div>
                </div>
            </main>
        );
    }
}
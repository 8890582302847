import React from 'react';

export default class CustomerInformationPrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Customer Information Privacy Policy</h1>

                    <div className="text">
                        <p>
                            Privacy Policy rules applicable for the handling of policyholder and other consumer information
                        </p>

                        <h4>Purpose</h4>
                        <p>
                            Our business success depends upon our ability to foster lasting customer relationships.
                            This Customer Information Privacy Policy ("Policy") sets up protection and security
                            standards for the handling of policyholder and other customer ("Customer") personally
                            identifiable data. This Policy is designed in promotion of the standards of ethics and
                            compliance set out in the Company's Code of Conduct, Do the Right Thing and is a part
                            of the Company's Corporate Compliance Program. <br /> <br />

                            <strong>Note</strong>: Capitalized terms used in this Policy, such as “PII,” “Company,” and “Company
                            Personnel” are explained in the following <strong>Appendix A: Definitions</strong>.
                        </p>

                        <p>
                            It’s important for our customers how we collect, manage, retrieve, use, secure, and
                            share their personally identifiable information (PII). Also, federal and state laws force
                            different demands on the Company when it collects, uses, shares, stores, transmits and
                            discards PII. A <strong>well-designed</strong> and well-structured privacy practices and procedures
                            allow the Company to meet legal requests and expand customer certainty and loyalty.
                            Failure to secure PII leaves the company open to highly targeted social engineering
                            attacks, heavy regulatory fines, and loss of customer trust.
                        </p>

                        <p>
                            Customer Information Privacy Policy will help Company Staff by:
                            <ul>
                                <li>Documenting the Company’s privacy principles and standards relating Customer PII;</li>
                                <li>Assisting the Company’s customer-centric strategy and policy; and</li>
                                <li>Creating a framework for describing our approach to Customers privacy.</li>
                            </ul>
                        </p>

                        <h4>Scope</h4>
                        <p>
                            The scope of this Policy is all personally identifiable information of Customers, whether
                            in electronic or paper format. It explains the importance of protecting the confidentiality
                            of PII
                        </p>

                        <h4>Roles</h4>
                        <p>
                            The Company is committed to dealing with customers fairly, honestly, and with integrity.
                            All representatives of the Company are in charge of implementing this Policy to the
                            extent it is applicable to the duties they perform for the Company. Employees involved
                            in the development of agreements under which Contractors will access, use or receive
                            PII must guarantee that such agreements fulfill this Policy. Some requests of this Policy
                            are not yet mentioned in existing standards; these standards will be subsequently
                            published. If employees of the Company have inquiries regarding to the applicability of
                            this Policy or any of its standards, they should consult with their supervisor or the
                            Privacy Executive. Specifically, you should keep the following guidelines in mind when
                            dealing with customers:
                        </p>

                        <h4>Policy</h4>
                        <p>
                            Privacy Guidelines are the most widely-accepted privacy principles, and the Company
                            has adopted the standards in this section to govern the Company’s data practices for
                            PII of Company Customers. The Company has diverse business associations with its
                            Customers (for example, an individual requesting a quote versus an existing
                            policyholder). This Policy perceives that the relevance of the security standards and
                            legal-related protection requirements may be different upon that business relationship
                            with an individual Customer.
                        </p>

                        <h4>Management</h4>
                        <p>
                            The Company sets up roles and liabilities for Company Personnel throughout the
                            organization to empower and support a Privacy Program.
                        </p>

                        <h4>Notice to Policyholders and Applicants</h4>
                        <p>
                            The Company defines a reasonable, clear, and prominent notice to Policyholders and
                            Applicants classifying the Company’s data practices and procedures with respect to the
                            PII collected about them at the occasions required by law. The Company will give some
                            other notification and statements required by law (e.g., collection of PII via public
                            website).
                        </p>

                        <h4>Choice</h4>
                        <p>
                            The Company describes any decisions it makes available to Customers with respect to
                            the Company’s security standards for PII (for example, sharing and marketing) and will
                            respect those decisions.
                        </p>

                        <h4>Collection</h4>
                        <p>
                            The Company only gathers PII about Customers for purposes identified in the privacy
                            data that we give to Customers.
                        </p>

                        <h4>Use, Retention and Disposal</h4>
                        <p>
                            PII is used consistently with the purposes described in the any applicable privacy notice
                            under which it is collected. The Company finds a way to keep PII about Customers only
                            as long as vital or for such longer period as might be required by relevant laws,
                            guidelines, regulation, or liabilities according to the agreements, and thereafter
                            appropriately disposes of such data.
                        </p>

                        <h4>Customer Access to PII</h4>
                        <p>
                            The Company gives Customers an opportunity to access, correct and change their PII
                            following written request as provided by law.
                        </p>

                        <h4>Disclosure of Information</h4>
                        <p>
                            The Company uncovers PII about Customers to third parties only in a manner that is
                            consistent with the initial privacy notice provided to such person (if required), and any
                            replacement protection notice if legally allowable. If Company shares PII with third
                            parties and/or Affiliates so that a Policyholder’s or Applicant’s consent and/or opt-out
                            rights are required by applicable laws, guidelines or contractual agreements, the
                            Company will get the required assent or give required opt out rights before disclosing
                            the PII. It is the Company’s privacy strategy and approach to only share PII with others
                            for purposes that would not require assent or the offering of an election to opt-out of the
                            sharing. Remedial action shall be taken in response to misuse or unauthorized
                            disclosure of personal information by a third party.
                        </p>

                        <h4>Information Security</h4>
                        <p>
                            Information security policy and procedures are documented and implemented to ensure
                            reasonable security for personal information collected, stored, used, transferred and
                            disposed by the Company. The Company implements physical, administrative, and
                            technical safeguards to protect Customer PII from loss and unauthorized access,
                            disclosure, alteration or destruction.
                        </p>

                        <h4>Data Quality</h4>
                        <p>
                            The Company comply reliable procedures to verify that PII about Policyholders,
                            Applicants, and Quote Requestors is secure, accurate, complete and current.
                        </p>

                        <h4>Monitoring and Enforcement</h4>
                        <p>
                            The Company establishes policies and practices to assess and monitor compliance with
                            this Policy and to resolve privacy related inquiries, complaints and disputes.
                        </p>

                        <h4>Compliance with Law, Third Party Contracts, and Industry Standards</h4>
                        <p>
                            The Company follows laws that apply to its use of PII. The Company complies with third
                            party contracts through which PII is obtained, including contracts or licenses for the
                            purchase or use of PII regarding Other Consumers. The Company complies with any
                            industry norms and standards related to privacy protection (e.g., Direct Marketing
                            Association) to which the Company is bound.
                        </p>

                        <h4>Audience</h4>
                        <p>
                            This Policy applies to all Company Employees as far as their position and role with the
                            Company is applicable.
                        </p>

                        <h4>Consequences and Enforcement</h4>
                        <p>
                            Representatives of the Company who refuse to conform with this Policy may be subject
                            to corrective action, up to and including end of employment. Company has the right to
                            exercise remedial action under agreements with Contractors who misuse or disregard
                            this Policy.
                        </p>

                        <h4>Standards and Related Policies</h4>
                        <p>
                            The Privacy Executive is authorized to designed, establish and maintain standards and
                            procedures for ensuring reasonable security of this Policy, and such standards are
                            mandatory for Company employees as if part of this Policy. Standards shall be
                            developed and implemented in cooperation with main privacy stakeholders and shall be
                            within the scope of the relevant privacy principles of this Policy. This Policy standards
                            concerns to the following policies:
                        </p>

                        <ul>
                            <li>IT Information Security Policy</li>
                            <li>IT Data Classification Standard</li>
                            <li>Do the Right Thing - The Employee Code of Conduct (Confidential Information & Privacy Policy and Information Technology Resources Usage Policy)</li>
                        </ul>

                        <h4>General Information and Questions</h4>
                        <p>
                            This Policy includes customers’ proprietary and confidential data details. It is solely
                            used for applying and disseminating to the individuals who are subject to this Policy.
                            This Policy is not intended to, nor does it, constitute or establish a contract, third party
                            beneficiary relationship, or an enforceable obligation of any kind on the part of the
                            Company.
                        </p>

                        <p>
                            In case of inquiries about the Company Policy, including, without limitation, its
                            applicability, please contact us without hesitation:
                        </p>

                        <h4>Incursus Support team </h4>
                        <p>E-mail: <mark>support@desktime.com</mark> </p>
                        <p>Phone call: 8664462877, 866-4Incursus</p>
                        <p><strong>Appendix A: Definitions</strong></p>

                        <p>
                            <strong>Affiliate</strong>: An entity that controls, is controlled by, or is under common control with another entity.
                        </p>

                        <p>
                            <strong>Applicant</strong>: For the purposes of this Policy, an Applicant is any individual who has
                            applied for a policy of insurance to be issued by Company.
                        </p>

                        <p>
                            <strong>Claimant</strong>: A person about whom the Company collects PII in connection with a claim
                            against a Policyholder.
                        </p>

                        <p>
                            <strong>Company</strong>: CSAA Insurance Group.
                        </p>

                        <p>
                            <strong>Company Personnel</strong>: Representatives of the Company and Contractors who work with customer PII.
                        </p>

                        <p>
                            <strong>Contractors</strong>: Third parties who access, store, or process PII about Company’s
                            Customers, such as our suppliers, contractors, and insurance producers.
                        </p>

                        <p>
                            <strong>CSAA Insurance Group</strong>: CSAA Insurance Exchange and its subsidiaries.
                        </p>

                        <p>
                            <strong>Customers</strong>: Customers refers to persons in any of the following categories:
                            Policyholders, Applicants, Quote Requestors, Claimants, and Other Consumers.
                        </p>

                        <p>
                            <strong>Government Identifier</strong>: A government-issued personal identifier such as a social
                            security number, passport number or driver’s license number.
                        </p>

                        <p>
                            <strong>Other Consumer</strong>: An individual about whom the Company has collected PII who is not
                            a Policyholder, Applicant, Quote Requestor, or Claimant, for example, persons identified
                            as excluded drivers from an insurance policy, persons identified in prospect lists, and
                            website visitors from whom the Company collects PII.
                        </p>

                        <p>
                            <strong>Personally, Identifiable Information ("PII")</strong>: PII means personally identifiable
                            information about Customers, excluding business contact information. PII is any
                            information about an individual maintained by the company, including any information that
                            can be used to distinguish or trace an individual‘s identity, such as name, social security
                            number, credit card information, date and place of birth, or even biometric records; and any
                            other information that is linked or linkable to an individual, such as medical, educational,
                            financial, and employment information. As we have mentioned above, examples of PII
                            include (but are not limited to) the Customer's first name or first initial and last name in
                            combination with any one or more of the following data elements:
                        </p>

                        <p>
                            <ul>
                                <li>Names, addresses, telephone numbers, date of birth</li>
                                <li>Financial account numbers (bank account, credit card, etc.)</li>
                                <li>Government identifiers (such as Social Security numbers or driver's license numbers)</li>
                                <li>Personal health information</li>
                                <li>Any lists derived from nonpublic individual information that could identify the individual</li>
                                <li>The Company policy numbers</li>
                                <li>The Company account payment or transaction history</li>
                                <li> Credit history </li>
                                <li>Photograph or video identifiable to an individual</li>
                            </ul>
                        </p>

                        <p>
                            Other information related to an individual that may directly or indirectly identify the
                            individual. (Sometimes, data that is not clearly related to a specific person can be crossreferenced with other data sets which can lead to a loss of identity of the person.Consult
                            with the Privacy Executive or Contracts & Privacy Team members before sharing
                            “anonymous and unknown” personal information).
                        </p>

                        <p>
                            <strong>Policyholder</strong>: An individual who owns an insurance policy issued by the Company,
                            including other persons insured under such policies and standards.
                        </p>

                        <p>
                            <strong>Privacy Executive</strong>: Associate General Counsel, Corporate Contracts & Privacy.
                        </p>

                        <p>
                            <strong>Privacy Program</strong>: The governance structure, principles, policy, guidelines,
                            communications, training, procedures and controls in place to organize and secure PII
                            in relevance with the Company’s Privacy Policy. The Company’s Privacy Program is led
                            and ruled by the Privacy Executive and is part of the Company’s overall compliance
                            program and procedure.
                        </p>

                        <p>
                            <strong>Quote Requestor</strong>: A person who has submitted PII to the Company in connection with
                            an inquiry for the cost and coverages for a protection strategy provided by the
                            Company.
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import {token, host, months, types} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class Blog extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            choosenType: null,
            choosenPage: 1,
            news: [],
            length: 0,
        }

        this.filter = {
            blog: {
                limit: 6, 
                sort: {createdAt: -1},
                find: {},
                skip: 1,
            }
        }

        this.types = {
            names: types,
            els: new Array(),
        }

        this.query = this.props.location.query;

        if(this.query !== undefined && this.query.type !== undefined){
            this.state.choosenType = types.indexOf(this.query.type);
            this.filter.blog.find.types = {$all: [this.query.type]}
        }
    }
    componentDidMount(){
        this._isMounted = true;

        this.getLength();
        this.getNews();
        window.scrollTo(0, 0);
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    getLength(){
        axios.post(host + '/api/blog/', {sort: this.filter.blog.sort, find: this.filter.blog.find}, {headers: {'authorization': token}})
        .then((res)  => {
            if (res.status === 200) {
                if(this._isMounted)
                    this.setState({
                        length: res.data.data.length,
                    })
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
          })
        .catch(err => {
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    getNews(concat = false){
        axios.post(host + '/api/blog/', this.filter.blog, {headers: {'authorization': token}})
        .then(res => {
            if (res.status === 200) {
                if(this._isMounted){
                    if(window.matchMedia("(max-width: 1023px)").matches && concat){
                        this.setState({
                            news: this.state.news.concat(res.data.data)
                        });
                    }else{
                        this.setState({
                            news: res.data.data
                        });
                    }
                }
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
        .catch(err => {
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    chooseType(i, type){
        this.filter.blog.find.types = {$all: [type]}

        this.setState({
            choosenType: i
        });

        this.getLength();
        this.getNews();
    }
    setSearch(btn){
        if(document.querySelector('.menuBtn').classList.contains('active_for_search')){
            btn.classList.remove('active');
            document.querySelector('.menuBtn').classList.remove('active_for_search');
        }else{
            btn.classList.add('active');
            document.querySelector('.menuBtn').classList.add('active_for_search');
        }
    }
    changePage(i){
        this.setState({choosenPage: i});

        this.filter.blog.skip = i;
        
        this.getNews();
        this.scroll_block.scrollIntoView();
    }
    seeMore(){
        this.filter.blog.skip++;

        this.setState({choosenPage: this.filter.blog.skip});
        
        this.getNews(true);
    }
    onSubmit(e){
        e.preventDefault();

        let query = this.search_content.children[0].value;


        this.filter.blog.query = query;

        if(query === '')
            delete this.filter.blog.query;

        this.getLength();
        this.getNews();

        this.searchBtn.classList.remove('active');
        document.querySelector('.menuBtn').classList.remove('active_for_search');
    }
    loadLis(){
        let lis = [];
        var length = Math.ceil(this.state.length/this.filter.blog.limit);

        for(let i = 0; i < length; i++){
            if(i + 1 === this.state.choosenPage){
                lis.push(<li key={i} className={i + 1 === this.state.choosenPage?'active':''}>{i + 1}</li>);
            }else{
                lis.push(<li key={i} className={i + 1 === this.state.choosenPage?'active':''} onClick={()=>this.changePage(i + 1)}>{i + 1}</li>);
            }
        }

        return lis;
    }
    loadTypes(){
        return this.types.names.map((type, i)=>{
            return <li key={i} className={this.state.choosenType === i?'active':''} ref={el=>this.types.els[i] = el} onClick={(e)=>this.chooseType(i, type)}>{type}</li>
        })
    }
    loadItems(){
        return this.state.news.map((news)=>{
            let date = new Date(news.createdAt);
            return <div key={news._id} className="item">
                <Link to={"/blog/" + news.url}>
                    <div className="img">
                        <img src={`${host}/public/uploads/${news.img.filename}`} alt={news.img.alt} />
                    </div>
                    <div className="text">
                        <div className="title">{news.title}</div>
                        <div className="intro_text">{news.intro_text}</div>
                        <div className="date">{months[date.getMonth()]} {parseInt(date.getDate())}, {date.getFullYear()}</div>
                    </div>
                </Link>
            </div>
        });
    }
    render(){
        return(
            <main id="blog">
                <MetaTags>
                    <title>Debt Consolidation Blog | Lendingbreak.com </title>
                    <meta name="description" content="Finding an accredited debt consolidation company that can give you guidance is hard.  We are here to help and guide you through the process. " />
                </MetaTags>

                {this.props.children}
                <div className="top">
                    <div className="text">
                        <h1>Blog</h1>
                        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                    </div>
                    <div className="img">
                        <img src={require('../assets/img/illust_8.png')} alt="Debt Consolidation Blog" />
                    </div>
                </div>
                <div className="search">
                    <div ref={el=>this.searchBtn = el} className="search_icon" onClick={(e)=>this.setSearch(e.target)}></div>
                    <form onSubmit={(e)=>this.onSubmit(e)}>
                        <label ref={el=>this.search_content = el}>
                            <input type="text" name="search_content" placeholder="Search" />
                        </label>
                    </form>
                    <div className="share">
                        <span>Follow us on: </span>
                        <ul>
                            <a href="#" className="fb"><li></li></a>
                            <a href="#" className="in"><li></li></a>
                            <a href="#" className="tw"><li></li></a>
                        </ul>
                    </div>
                </div>
                {/* <div ref={el=>this.scroll_block = el} className="types">
                    <ul>
                        {this.loadTypes()}
                    </ul>
                </div> */}
                <div className="items">
                    {this.loadItems()}
                </div>
                <div className="pages">
                    <ul>
                        {this.loadLis()}
                    </ul>
                    {this.state.news.length < this.state.length?<div className="seemore" onClick={()=>this.seeMore()}>view more</div>:null}
                </div>
            </main>
        );
    }
}
import React from 'react';

export default class InfoSecPolicy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>
                        INFORMATION SECURITY POLICY
                        INFORMATION TECHNOLOGY DISASTER RECOVERY POLICY
                    </h1>

                    <div className="text">
                       <h4>Purpose of Policy</h4>

                       <p>
                       This document delineates our policies and procedures for technology disaster recovery. The aim of this policy is to identify requirements that mitigate business interruptions, to secure essential business processes from the effects of major information system or disaster failures, and to ensure timely resumption.
                       </p>

                       <p>
                       Business continuity is the overarching concern. The disaster recovery standards in this policy provide a systematic approach for safeguarding the vital technology and data managed by the Company. In the event of an actual emergency situation, modifications to this document may be made to ensure physical safety of our people, our systems, and our data.
                       </p>

                       <h4>1.0 Policy</h4>
                        <h5>1.1 Information Systems Continuity and Recovery</h5>

                        <ul>
                            <li>The company shall decide and assess its data systems requirements and the stability and recuperation of the management of information systems in emergency circumstances, such as crises or disasters;</li>
                            <li>The company shall build up, develop, document, execute, and maintain standards, procedures, and regulations to guarantee the necessary degree of continuity and recuperation for information Systems during an unfavorable circumstance.</li>
                            <li>Continuity and recovery controls of the information systems shall be checked, reviewed and updated at scheduled and designed intervals</li>
                        </ul>

                        <h4>2.0 Roles and Responsibilities</h4>
                        <h5>2.1 Employees</h5>
                        <p>All employees are liable for:</p>
                        <ul>
                            <li>Following authorized disaster recovery and business continuity plans and standards as controlled or directed by his/her manager or supervisor.</li>
                        </ul>

                        <h4>Awareness and Enforcement</h4>
                        <p>
                        Violation of this policy standards may result in the refusal of requests, disability of user rights, and the restricting access of IT recourses or services. Representatives of the Company who disregard this strategy may also be dependent to disciplinary or corrective action and measures, 
                        up to and including dismissal. For employees of contractors, business partners or consultants, violation of this policy may affect your employer's relationship with the Company, and may bring about corrective and protective action from your employer.
                        </p>

                        <p>
                        The document will be sent to the owner for approval on an annual basis. The paper will then be circulated by the owner to key users for knowledge and understanding after each annual review. IT Governance would assist and make the process simpler.
                        </p>

                        <h4>Exceptions</h4>
                        <p>
                            <mark>All exceptions must be processed by department leader. </mark>
                        </p>

                        <p>
                            <mark>Any exception must be reviewed and approved by Company Risk Owners. </mark>
                        </p>

                        <h4>Policy Contacts</h4>
                        <p>
                            <mark><strong>Policy Owner</strong>: CTO</mark> <br />
                            <mark><strong>SME</strong></mark>: CISO<br />
                            <mark><strong>Others</strong></mark>: Rafik Avetisyan and Atul Kapoor
                        </p>

                        <h4>Recent Changes</h4>
                        <p>
                        <mark>Current revision history is in</mark> Google Docs
                        </p>

                        <h4>Copyright</h4>
                        <p>
                        This is the confidential, undisclosed property of the Company. Receipt or possession of it does not convey any rights to publish, disclose, copy, use, or allow anyone to use it without the Company's explicit written permission. Usage must be strictly in compliance with the license agreement between user and company. Copyright @2020 Incursus. All Rights Reserved. This copyright notice should not be interpreted as publishing facts. The product names listed in this document may be and are hereby recognized as trademarks or registered trademarks of their respective companies.
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';
import {Link} from 'react-router-dom';

export default class Footer extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <footer>
                <div className="top">
                    <div className="start">
                        <div className="logo"><Link to="/" onClick={(e)=>{this.menuBtn.classList.remove('active')}}><img src={require('../assets/img/logo.png')} alt="Logo of Lending Break"/></Link></div>
                        <p>Lending Break finds the best debt consolidation solutions for people just like you. Instantly.</p>
                    </div>
                    <div className="debthunch">
                        <h4>Lending Break</h4>
                        <ul>
                            <li><Link to="/about-us">About Us</Link></li>
                            <li><Link to="/offers">Offers</Link></li>
                            <li><Link to="/licensing">Licensing</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-of-service">Terms of Service</Link></li>
                            <li><Link to="/contact-us">Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="products">
                        <h4>Products</h4>
                        <ul>
                            <li><Link to="/debt-consolidation">Debt Consolidation</Link></li>
                            <li><Link to="/personal-loan">Personal Loan</Link></li>
                            <li><Link to="/line-of-credit">Line of Credit</Link></li>
                        </ul>
                    </div>
                    <div className="contact">
                        <h4>Contact us</h4>
                        <ul>
                            <li><Link to={{pathname: "https://www.facebook.com/Lendingbreakfinance"}} target="_blank">Facebook</Link></li>
                            <li><Link to={{pathname: "https://www.linkedin.com/company/lendingbreak/"}} target="_blank">Linkedin</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="bottom">
                    <p>&copy; {new Date().getFullYear()} Lending Break. All Rights Reserved</p>
                </div>
            </footer>
        );
    }
}
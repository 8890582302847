import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";


import './assets/css/App.css';
import './assets/css/media.css';
import './assets/css/fonts.css';

import routes from './routes';


import Header from './components/header';
import Footer from './components/footer';

class App extends React.Component {
  constructor(){
    super();
  }
  getRoutes(routes){
    const title = 'Lending Break';
    
    return routes.map((route, index)=>{
      return <Route key={index} path={route.path} exact render={(params) => <route.component {...params}>
        <Helmet>
            {/* <title>{route.title + ' | ' + title}</title> */}
        </Helmet>
      </route.component>}/>
    });
  }
  render(){
    return(
      <Router>
        <div className="App">
          <Header/>
          <Switch>
            {this.getRoutes(routes)}
            <Redirect to="/"/>
          </Switch>
          <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;

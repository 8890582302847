import React from 'react';

export default class ConfidentialInfoAndPrivacyPolicy extends React.Component {
    constructor(props){
        super(props);

        this.state = {
        }
    }
    
    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Confidential Information and Privacy Policy </h1>

                    <div className="text">
                        <p>
                            Privacy Policy guidelines on the security of personal data for policyholder, customer, employee and vendor
                        </p>

                        <p>
                        Our Privacy Policy sets out principles on the collection, protection, use and disclosure of
                        personal information. Incursus is legally committed to secure private data and
                        information relating to policyholders, customers, employees, and vendors. Employees
                        have access to a variety of confidential information while employed at the Company and
                        each of them has a duty to respect and safeguard the confidentiality of the Company’s
                        information and the information of our suppliers and customers, Unauthorized access,
                        use, sharing and disclosure of confidential information and private business data could
                        cause competitive harm to the Company or its customers and could result in legal
                        liability to you and the Company. In addition, you must refrain from using
                        confidential information obtained in the course of your employment for personal gain or
                        financial benefit. You are obligated to protect and maintain the confidentiality of
                        policyholder, customer, employee, and vendor information in the company's possession.
                        Confidential information includes all non-public information that might be of use to
                        competitors, or, if disclosed, harmful to the Company or its customers. So, in this case
                        you should also keep as secret any compilations of information prepared by or for the
                        benefit of the company, even if individual items of information in the compilation are
                        accessible from open sources or documents.
                        </p>

                        <p>
                        The Company’s employees should conduct their business and social activities so as not
                        to risk inadvertent disclosure of confidential information. They must also keep
                        information received from third parties confidential in accordance with any nondisclosure agreements between the company and such third parties and applicable law.
                        Unauthorized sharing and reveal of personal data and information will incur substantial
                        liability, risk and unnecessary cost on the Company.
                        </p>

                        <p>
                        The Company’s employees are only to access, use, and disclose confidential
                        information that is necessary for them to have in the course of performing their duties.
                        When handling confidential information, you must take necessary precautions to ensure
                        its protection and uphold the company's published consumer privacy notices and
                        policies, standards, procedures and processes. The content of any confidential
                        information might be disclosed only with written consent of appropriately authorized
                        people. Confidential information and matters should be secretly stored by the
                        employees of the Company.
                        </p>

                        <h4>Below you can find some examples of confidential information: </h4>

                        <ul>
                            <li>Financial data and internal business statistics</li>
                            <li>Current and future business plans and strategies</li>
                            <li>Company manuals and other internal business processes and procedures</li>
                            <li>Lists of persons insured by the company or any of its affiliates or business partners</li>
                            <li>
                            Names, addresses, telephone and fax numbers, and email addresses of employees or customers
                            </li>
                            <li>Personal medical information and records</li>
                            <li>Social Security numbers</li>
                            <li>Driver license numbers</li>
                            <li>Credit card account numbers</li>
                            <li>Motor vehicle records</li>
                            <li>Consumer reports</li>
                            <li>Information relating to claims investigations, litigation, or settlements.</li>
                        </ul>

                        <p>
                        Due to the importance of confidential information and privacy policy, background
                        investigations are conducted on all candidates and applicants for employment with the
                        company. To help employees in understanding their duties regarding to confidential
                        information and privacy protection, each representative will complete the company's
                        annual information security awareness, privacy and /or data security training or other
                        role-based training (e.g. secure coding) relating to information protection and privacy.
                        This Code applies to all employees of the Company.
                        </p>

                        <p>
                        All of them have a duty to report any known or suspected violation of this Code,
                        including any violation of the laws, rules, regulations or policies that apply to the
                        Company. In case of unauthorized access to, customer or employee personal
                        information, the employees should contact our Support team with this e-mail:
                        support@desktime.com or call at 8664462877, 866-4Incursus.
                        </p>

                        <p>
                        It is reasonable to expect workers to have a basic sense of responsibility to the
                        company and willingness to protect a variety of important assets such as intellectual
                        property and business secrets. It is worth to mention that an employee's obligations
                        regarding the security of proprietary information apply at all times during an employee's
                        employment with the company and will continue indefinitely after your employment ends
                        until such time as the company gives you express authorization to use or disclose such
                        information. The company reserves the right to avail itself of all legal or equitable
                        remedies to forestall or to recoup damages incurred as a result of, the impermissible
                        use or disclosure of secret data. All employees of the Company are required to comply
                        with the Privacy Policy in the execution of their daily activities.
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
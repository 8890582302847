import React from 'react';

export default class CompliancePolicy extends React.Component {
    constructor(props){
        super(props);

        this.state = {
        }
    }
    
    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Compliance Policy</h1>

                    <div className="text">
                        <p>
                            Our Corporate Policy and Code of Business Ethics for conducting the business ethically
                            in accordance to the applicable laws and regulations
                        </p>

                        <h4>Policy Statement</h4>
                        <p>
                            Incursus is committed to managing its legal compliance obligations in a proactive,
                            ongoing and responsible manner. As a company we fulfill our legal commitments and
                            encourage full compliance with all applicable laws and rules in line with high standards
                            of ethical business conduct. We strive to maintain good business practices that
                            empower us to precisely record all trades and reduce the effect of legal and regulatory
                            risks.
                        </p>

                        <h4>Policy Purpose</h4>
                        <p>
                            This policy provides guidance to comply with all laws and regulations, to protect our
                            Company from conduct that can threaten our reputation, brand and image and to
                            operate with high ethical standards in all business dealings
                        </p>

                        <h4>Roles and Responsibilities</h4>
                        <p>
                            Compliance is a responsibility and liability that individual employees should share,
                            regardless of their position within the company. For the company to meet its strategic
                            and operational goals, we strive to ensure and incorporate efforts to monitor, assess
                            and evaluate compliance with legal and regulatory requirements. Together with Internal
                            Audit we are committed to conduct all applicable laws, rules and regulations in
                            accordance with the highest ethical principles. 
                        </p>

                        <p>
                            You have contacts available to help you in case you have questions or concerns
                            relating to ethics and legal compliance violation:
                        </p>

                        <p>
                            You should report such violation through one of the communication channels described below:
                        </p>

                        <p>
                            Phone number: 8664462877, 866-4Incursus <br />
                            Address: 1212 Broadway Plaza, Second Floor, Walnut Creek, CA, 94596
                        </p>

                        <p>
                            You are encouraged to ask questions, and we are all obligated to report compliance violations
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import Helmet from 'react-helmet';

import {host, token, months} from '../assets/options';

import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
  } from "react-share";


export default class SingleBlog extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            suggestions: [],
            news: {
                title: '',
                text: '',
                intro_text: '',
                types: [],
                img: {
                    filename:'',
                    alt:'',
                },
                createdAt: new Date()
            },
        }
    }
    componentDidMount(){
        this._isMounted = true;

        this.getNews(this.props.match.params.url);

        window.scrollTo(0, 0);
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    getNews(url) {
        axios.post(host + '/api/blog/' + url, {}, {headers: {'authorization': token}})
        .then((res)  => {
            if (res.status === 200) {
                if(this._isMounted)
                    this.setState({
                        news: res.data.data,
                    });
                    this.getSuggestions(res.data.data._id);
                    window.scrollTo(0, 0);
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
          })
        .catch(err => {
            if (err.response.status === 404) {
                this.props.history.push('/404');
            }
             
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    getSuggestions(id){
        axios.post(host + '/api/blog/', {find: {_id: {$nin: id}}, limit: 8, sort: {createdAt: -1}}, {headers: {'authorization': token}})
            .then(res_blog => {
            if (res_blog.status === 200) {
                if(this._isMounted)
                    this.setState({
                        suggestions: res_blog.data.data
                    });
            } else {
              const error = new Error(res_blog.error);
              throw error;
            }
          })
        .catch(err => {
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    onSubmit(e){
        e.preventDefault();

        axios.post(host + '/api/subscribtion/add', {email: this.email.firstChild.value}, {headers: {'authorization': token}})
            .then((res)  => {
                if (res.status === 200) {
                    this.email.firstChild.value = '';
                    this.email.firstChild.placeholder = 'You subscribed.';
                    this.email.classList.add('right');
                } else {
                  const error = new Error(res.data.error);
                  throw error;
                }
              })
            .catch(err => {
                console.error(err.response?err.response.data.error:err);
                this.email.firstChild.value = '';
                this.email.firstChild.placeholder = 'You have already subscribed.';
                this.email.classList.add('wrong');
            });
    }
    loadSuggestions(){
        return this.state.suggestions.map((news)=>{
            let date = new Date(news.createdAt);
            return <div key={news._id} className="item" onClick={()=>{this.getNews(news.url)}}>
                <Link to={"/blog/" + news.url}>
                    <div className="text">
                        <div className="title">{news.title}</div>
                        <div className="date">{months[date.getMonth()]} {parseInt(date.getDate())}, {date.getFullYear()}</div>
                    </div>
                    <div className="img">
                        <img src={`${host}/public/uploads/${news.img.filename}`} alt={news.img.alt} />
                    </div>
                </Link>
            </div>
        })
    }
    render(){
        let { news } = this.state;
        let date = new Date(news.createdAt);
        
        return(
            <main id="single_blog">
                <Helmet>
                    <title>{news.title}</title>
                    <meta property="og:title" content={news.title} />
                    {/* <title>{news.title + this.props.children.props.children.props.children}</title> */}
                    {/* <meta property="og:title" content={news.title + this.props.children.props.children.props.children} /> */}
                    <meta property="og:image" content={host + '/public/uploads/' + news.img.filename} />
                    <meta property="og:description" content={news.intro_text} />
                    <meta property="og:url" content={window.location.href} />
                </Helmet>
                <div className="img">
                    {news.img.filename?<img src={`${host}/public/uploads/${news.img.filename}`} alt={news.img.alt} />:null}
                </div>
                <div className="date">
                    {months[date.getMonth()]} {parseInt(date.getDate())}, {date.getFullYear()}
                </div>
                <div className="title">
                    <h1>{news.title}</h1>
                </div>
                <div className="intro_text">
                    {news.intro_text} 
                </div>
                <div className="share">
                    <ul>
                        <FacebookShareButton className="fb" url={window.location.href}><li></li></FacebookShareButton>
                        <LinkedinShareButton className="in" url={window.location.href}><li></li></LinkedinShareButton>
                        <TwitterShareButton className="tw" url={window.location.href}><li></li></TwitterShareButton>
                    </ul>
                </div>
                <div className="text" dangerouslySetInnerHTML={{__html: news.text}}></div>
                <div className="types">
                    <ul>
                        {news.types.map((type, i)=>
                            <Link key={i} to={{pathname: `/blog`, query: {type: type}}}><li>{type}</li></Link>
                        )}
                    </ul>
                </div>
                <div className="suggestions">
                    <h2>More News</h2>
                    <div className="items">
                        {this.loadSuggestions()}
                    </div>
                </div>
                <div className="subscribe">
                    <div className="coin"></div>
                    <form method="POST" onSubmit={(e)=>this.onSubmit(e)}>
                        <h3>Subscribe To Our Newsletter</h3>
                        <label ref={el=>this.email = el} htmlFor="email">
                            <input id="email" type="email" name="email" placeholder="Email" onChange={()=>{this.email.classList.remove('wrong'); this.email.classList.remove('right'); this.email.firstChild.placeholder = 'Email';}} required/>
                            <button type="submit">subscribe</button>
                        </label>
                    </form>
                    <div className="coin right"></div>
                </div>
            </main>
        );
    }
}
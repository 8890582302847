import base64 from 'base-64';

import axios from 'axios';

export const token = `Basic ${base64.encode('weflex:QLgGYCBu0hdR1STXrpjS')}`;

export const host = 'https://lendingbreak.com' //http://localhost:5000

export const contact_info = async ()=>{
    var res = await axios.post(host + '/api/contactinfo/', {}, {headers: {'authorization': token}});

    return res.data.data;
}

export const options = {
    credit_score: [
        {
            value: 720,
            label: 'Excellent (720+)'
        },
        {
            value: 690,
            label: 'Good (660-719)'
        },
        {
            value: 630,
            label: 'Fair (600-659)'
        },
        {
            value: 600,
            label: 'Limited (<600)'
        },
    ],
    business_owner: [
        {
            value: 1,
            label: 'YES'
        },
        {
            value: 0,
            label: 'NO'
        },
    ],
    state: [
        { value: 'AL', label: 'Alabama'}, { value: 'AK', label: 'Alaska'}, { value: 'AZ', label: 'Arizona'}, { value: 'AR', label: 'Arkansas'}, { value: 'CA', label: 'California '}, { value: 'CO', label: 'Colorado'}, { value: 'CT', label: 'Connecticut'}, { value: 'DE', label: 'Delaware'}, { value: 'FL', label: 'Florida'}, { value: 'GA', label: 'Georgia'}, { value: 'HI', label: 'Hawaii'}, { value: 'ID', label: 'Idaho'}, { value: 'IL', label: 'Illinois'}, { value: 'IN', label: 'Indiana'}, { value: 'IA', label: 'Iowa'}, { value: 'KS', label: 'Kansas'}, { value: 'KY', label: 'Kentucky'}, { value: 'LA', label: 'Louisiana'}, { value: 'ME', label: 'Maine'}, { value: 'MD', label: 'Maryland'}, { value: 'MA', label: 'Massachusetts'}, { value: 'MI', label: 'Michigan'}, { value: 'MN', label: 'Minnesota'}, { value: 'MS', label: 'Mississippi'}, { value: 'MO', label: 'Missouri'}, { value: 'MT', label: 'Montana'}, { value: 'NE', label: 'Nebraska'}, { value: 'NV', label: 'Nevada'}, { value: 'NH', label: 'New Hampshire'}, { value: 'NJ', label: 'New Jersey'}, { value: 'NM', label: 'New Mexico'}, { value: 'NY', label: 'New York'}, { value: 'NC', label: 'North Carolina'}, { value: 'ND', label: 'North Dakota'}, { value: 'OH', label: 'Ohio'}, { value: 'OK', label: 'Oklahoma'}, { value: 'OR', label: 'Oregon'}, { value: 'PA', label: 'Pennsylvania'}, { value: 'RI', label: 'Rhode Island'}, { value: 'SC', label: 'South Carolina'}, { value: 'SD', label: 'South Dakota'}, { value: 'TN', label: 'Tennessee'}, { value: 'TX', label: 'Texas'}, { value: 'UT', label: 'Utah'}, { value: 'VT', label: 'Vermont'}, { value: 'VA', label: 'Virginia'}, { value: 'WA', label: 'Washington'}, { value: 'WV', label: 'West Virginia'}, { value: 'WI', label: 'Wisconsin'}, { value: 'WY', label: 'Wyoming'}
    ]
}

export const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const types = [
    'CREDIT CARD DEBT',
    'FINANCIAL PLANNING',
    'DEBT CONSOLIDATION',
    'PERSONAL LOANS',
    'CREDIT CARDS',
    'STUDENT LOAN DEBT',
    'BUSINESS DEBT',
    'MEDICAL DEBT'
]
import React from 'react';

export default class WorkplaceViolanceAndPrevention extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Workplace Violence Prevention</h1>

                    <div className="text">
                        <p>
                            This policy will assist in complying the company’s laws concerning workplace safety.
                        </p>

                        <h4>Policy Purpose</h4>
                        <p>
                            Incursus is committed to preventing workplace violence and to maintaining a safe work
                            environment. Incursus has adopted this guideline to deal with intimidation, harassment or other
                            threats of or actual violence that may occur onsite or offsite during work-related activities. This
                            Policy is intended to guarantee the company’s consistence with laws with respect to work
                            environment wellbeing, but it is not intended to impose responsibilities outside those laws. This
                            Policy also contains workplace violence issues that that can possibly affect the work
                            environment.
                        </p>

                        <h4>Policy Statement</h4>
                        <p>
                            Incursus is committed to ensuring a secure and healthy working environment, free from real
                            violence, attempted violence or intimidation. The company acknowledges that violence in the
                            workplace is a matter of health and safety and human resources, and will take appropriate steps
                            to avoid violence in the workplace and to protect workers at work. Anyone found to be
                            responsible for threats of or actual violence or other conduct that is in violation of these
                            guidelines will be subject to prompt disciplinary action up to and including termination of
                            employment.
                        </p>

                        <h4>Scope</h4>
                        <p>
                            All employees, contingent workers, visitors, and anyone else whom employees come into contact
                            at the workplace, are covered under this policy.
                        </p>

                        <h4>Policy Details</h4>

                        <p>
                            Workplace violence is any conduct that is severe, offensive or threatening enough to cause an
                            employee to reasonably fear for his or her personal security or the safety of his or her family,
                            friends, coworkers and/or property to such a degree that a hostile, abusive, or intimidating work
                            environment is or may be created. Any and all acts of intimidation, threats, or acts of violence
                            by an employee are considered serious misconduct. Employees are expected to refrain from
                            fighting, or other conduct that may be dangerous to others. Conduct that threatens intimidates or
                            coerces another employee, customer, vendor or business associate will not be tolerated.
                            Incursus’s resources may not be used to threaten, stalk or harass anyone at or outside the
                            workplace. The prevention of workplace violence is a shared responsibility. We have a responsibility to
                            provide a safe workplace. Examples of behaviors that could pose a risk of violence in the
                            workplace and should be reported to the appropriate authority at Incursus Company
                            include, but are not limited to: <br /><br />

                            <u>Intimidation</u>: To coerce another person in a manner that could reasonably cause that person to
                            fear for his or her safety or the safety of others. Harassing or threatening phone calls, emails, or
                            other communications may be considered intimidation.
                            <br /><br />
                            <ul>
                                <li><u>Threat of Violence</u>: A communicated threat to inflict physical or other harm to an
                                    employee, or the employee's family or property.</li>
                                <li>
                                    <u>Act of Violence</u>: The intentional use of physical force against another person or the
                                    destruction of property.

                                </li>
                            </ul>

                            <br />

                            Employees are forbidden to possess dangerous or illegal materials such as knives, explosives, or
                            weapons on company premises to the fullest degree allowed by law. We need your support and
                            cooperation so that we can successfully enforce this policy and maintain a secure working
                            environment. Do not ignore acts of violence, threats, harassment, intimidation or other disruptive
                            conduct .If one of our staff or someone else notices or faces such conduct, report it to your
                            manager as soon as possible so we can investigate and react in a timely manner. If you can’t
                            contact to the manager, or if your manager is the one making the threat or committing the
                            violence, contact the <strong>Security Operations Center at 1-925-279-2345</strong>, 24 hours a day, 7 days a
                            week. In all occasions, on the off chance that you think there is an emergency requiring a quick
                            reaction, you should call 911.

                            <br /><br />

                            When a manager is informed about behavior that possibly violates this policy, he or she should
                            contact Security Operations Center at 1-925-279-2345, 24 hours a day, 7 days a week. In all
                            events, if you think the situation is perceived dangerous there is an emergency requiring an
                            urgent response, you can call 911 and request assistance.
                            After reacting to a situation of workplace abuse, a manager should immediately report the situati
                            on to his or her chain of management.

                            <br /><br />

                            We treat all data received from the representatives of the company as confidential where
                            warranted and necessary. So as to react properly to work environment viciousness circumstances
                            and conditions, some exchange of information may be required in order to respond effectively to
                            situations of workplace violence, and where appropriate, the Company will report violent
                            incidents to local law enforcement authorities.When reporting a threat or incident of violence;
                            the employee should be as specific and detailed as possible. Employees should not place
                            themselves in peril, nor should they attempt to intercede during an incident.

                            <br /><br />

                            Indirect or direct threats of violence, incidents of actual violence, and suspicious individuals or
                            activities should be reported as soon as possible to a supervisor, security personnel, human
                            resources (HR), member of the Incursus’s threat management team or any member of senior
                            management. Incursus will promptly and thoroughly investigate all reports of threats of violence
                            or incidents of actual violence and of suspicious individuals or activities. We do not tolerate
                            threats or reprisals against employees who raise their concerns at work in good faith. If you
                            experience retaliation for reporting any concern about the workplace, including a concern about
                            potential workplace violence, notify your manager, Human Resources Consultant, or the
                            <strong> Company's toll-free EthicsLine at 1-877-534-0373 or</strong> <a href="http://csaa.ethicspoint.com">http://csaa.ethicspoint.com</a> right away.

                            <br /><br />

                            We provide assistance and time off to employees who are affected by domestic violence, sexual
                            assault, or certain crimes. To make plans for time off, you should provide advance notice to your
                            supervisor or manager. The company also requires employees to provide some written proof
                            that validates the reason for your time off. Time off is unpaid, but you may use any available
                            PTO to be compensated.

                            <br /><br />

                            For employees who are victims of domestic violence or sexual assault, unpaid time off may be
                            taken to: <br /><br />

                            <ul>
                                <li>Obtain or attempt to obtain judicial relief, such as a restraining order</li>
                                <li>Seek medical attention for injuries caused by domestic violence or sexual assault</li>
                                <li>Obtain services from a domestic violence shelter, program or rape crisis center</li>
                                <li>Obtain psychological counseling</li>
                                <li>
                                    Participate in safety planning and take other actions to increase safety, including
                                    temporary or permanent relocation
                                </li>
                            </ul>

                            <br />

                            For employees who are victims of such crimes or victims' family members, unpaid time off can
                            be taken to attend criminal-related court hearings regardless of whether your presence at the
                            hearing is necessary.

                            <br /><br />

                            To allow victims of such crimes for time off, you or your spouse, domestic partner, child,
                            stepchild, brother, stepbrother, sister, stepsister, mother, stepmother, father or stepfather must
                            have been the victim of a violent crime, serious crime or certain offenses involving robbery or
                            embezzlement.

                            <br /><br />
                            <mark>
                                The HealthAdvocate EAP and Work/Life Program is a confidential counseling, education, and
                                referral service accessible to employees and their household members. For more information
                                about the company's EAP and Work/Life Program, <strong>please visit the <a href="http://vista/hr/Pages/Health-Advocates-EAP-and-Health-Advocacy-Program-.aspx"> Benefits and Wellness </a>
                                    website or call 1-866-799-2728</strong>.
                            </mark>
                        </p>

                        <h4>Roles and Responsibilities</h4>
                        <p>
                            The Physical Security Department shall be responsible for the implementation and evaluation of
                            this Policy under the guidance of the Senior Security Administrator.
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
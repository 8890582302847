import React from 'react';
import axios from 'axios';

import {token, host} from '../assets/options';

export default class Unsubscribe extends React.Component{
    constructor(props){
        super(props);

        this.state = {
        }
    }
    componentDidMount(){
        axios.delete(`${host}/api/subscribtion/${this.props.match.params.email}/${this.props.match.params.hash}`, {headers: {'authorization': token}})
            .then(res => {
            if (res.status === 200) {
                if(res.data.success)
                    this.msg.innerHTML = 'Unsubscribe Successful';
                else
                    this.msg.innerHTML = 'Something went wrong, please try again.';
            } else {
              const error = new Error(res.error);
              throw error;
            }
          })
        .catch(err => {
            if(err.response.status === 403){
                this.msg.innerHTML = 'Something went wrong, please try again.';
            }
            console.error(err.response?err.response.data.error:err);
        }); 

        window.scrollTo(0, 0);
    }
    render(){
        return(
            <main id="unsubscribe">
                {this.props.children}
                <div>
                    <div ref={el=>this.msg = el} className="msg"></div>
                </div>
            </main>
        );
    }
}
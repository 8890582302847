import Index from "./views/index";
import ContactUs from "./views/contactUs";
import AboutUs from "./views/aboutUs";
import Licensing from "./views/licensing";
import PrivacyPolicy from "./views/privacyPolicy";
import TermsOfService from "./views/termsOfService";
import DebtConsolidation from "./views/debtConsolidation";
import PersonalLoan from "./views/personalLoan";
import LineOfCredit from "./views/lineOfCredit";
import Blog from "./views/blog";
import SingleBlog from "./views/singleBlog";
import Offers from "./views/offers";
import Unsubscribe from "./views/unsubscribe";
// import path from "path/posix";

import CompliancePolicy from './views/CompliancePolicy';
import ConfidentialInfoAndPrivacyPolicy from './views/ConfidentialInfoAndPrivacyPolicy';
import CustomerInformationPrivacyPolicy from './views/CustomerInformationPrivacyPolicy';
import DiscriminationAndHarassment from './views/DiscriminationAndHarassment';
import EnterpriseBusinessContinuity from './views/EnterpriseBusinessContinuity';
import WorkplaceViolanceAndPrevention from './views/WorkplaceViolanceAndPrevention';
import InfoSecPolicy from './views/InfoSecPolicy';
import TermsOfUse from './views/TermsOfUse'

export default [
    {
        path: '/',
        title: '',
        component: Index,
    },
    {
        path: '/contact-us',
        title: 'Contact Us',
        component: ContactUs,
    },
    {
        path: '/about-us',
        title: 'About Us',
        component: AboutUs,
    },
    {
        path: '/licensing',
        title: 'Licensing',
        component: Licensing,
    },
    {
        path: '/privacy-policy',
        title: 'Privacy Policy',
        component: PrivacyPolicy,
    },
    {
        path: '/terms-of-service',
        title: 'Terms of Service',
        component: TermsOfService,
    },
    {
        path: '/debt-consolidation',
        title: 'Debt Consolidation',
        component: DebtConsolidation,
    },
    {
        path: '/personal-loan',
        title: 'Personal Loan',
        component: PersonalLoan,
    },
    {
        path: '/line-of-credit',
        title: 'Line of Credit',
        component: LineOfCredit,
    },
    {
        path: '/blog',
        title: 'Blog',
        component: Blog,
    },
    {
        path: '/blog/:url',
        title: '',
        component: SingleBlog,
    },
    {
        path: '/offers',
        title: 'Offers',
        component: Offers,
    },
    {
        path: '/unsubscribe/:email/:hash',
        title: 'Unsubscribe',
        component: Unsubscribe,
    },
    {
        path: '/compliance-policy',
        title: "Compliance Policy",
        component: CompliancePolicy
    },
    {
        path: '/confidential-info-and-privacy-policy',
        title: "Confidential Info and Privacy Policy",
        component: ConfidentialInfoAndPrivacyPolicy
    },
    {
        path: '/customer-information-privacy-policy',
        title: "Customer Information Privacy Policy",
        component: CustomerInformationPrivacyPolicy
    },
    {
        path: "/discrimination-and-harassment",
        title: "Discrimination and Harassment",
        component: DiscriminationAndHarassment
    },
    {
        path: "/enterprise-business-continuity",
        title: "Enterprise Business Continuity",
        component: EnterpriseBusinessContinuity
    },
    {
        path: "/workplace-violance-and-prevention",
        title: "Workplace violance and prevention",
        component: WorkplaceViolanceAndPrevention
    },
    {
        path: "/info-sec-policy",
        title: "Info Sec Policy",
        component: InfoSecPolicy
    },
    {
        path: "/terms-of-use",
        title: "Terms of Use",
        component: TermsOfUse
    }
]
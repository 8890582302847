import React from 'react';

import {CheckInput} from '../assets/plugins/functions';

import MetaTags from 'react-meta-tags';

export default class PersonalLoan extends React.Component{
    constructor(props){
        super(props);

        this.state = {
        }
    }
    componentDidMount(){
        window.scrollTo(0, 0);
    }
    onSubmit(e){
        e.preventDefault();

        let verify = new CheckInput().checkOnSubmit(this, {'personal_key': this.personal_key.firstChild.value});

        if(verify){
            console.log(this.personal_key.firstChild.value);
        }
    }
    render(){
        return(
            <main id="services" className="personal_loan">
                <MetaTags>
                    <title>The best personal loans for debt consolidation | LendingBreak</title>
                    <meta name="description" content="Are you trying to pay debts off as fast as you can? We will match you with the best personal loan options!" />
                </MetaTags>

                {this.props.children}
                <div className="top">
                    <div className="left">
                        <h1>The best personal loans for debt consolidation</h1>
                        <p>We will match you with the best personal loan options!</p>
                        <div className="group">
                            <button onClick={()=>{this.props.history.push('/offers')}} className="standart">
                                see my offers
                            </button>
                            <span>This will not affect your credit score</span>
                        </div>
                        <form method="POST" onSubmit={(e)=>this.onSubmit(e)}>
                            <p>I have a <span onClick={()=>{this.personal_key.firstChild.style.visibility = 'visible';}}>Personal Key</span> from a mail offer *</p>
                            <label ref={el=>this.personal_key = el}>
                                <input name="personal_key" placeholder="Enter your Personal Key" onChange={()=>{new CheckInput().checkOnChange(this.personal_key)}} required/>
                            </label>
                        </form>
                    </div>
                    <div className="right">
                        <img src={require('../assets/img/illust_6.png')} alt="best personal loan options" />
                    </div>
                    <p className="warning">* Your Personal Key located in the upper right hand corner of yor mail offer</p>
                </div>
                <div className="second">
                    <ul>
                        <li>
                            <h3>Provide personal loan options for debt consolidation​​</h3>
                            <div className="text">
                                <p>Our mission is to help you become debt-free and get your current financial circumstances resolved. We do this by finding practical debt consolidation solutions that work for you. </p>
                                <p>Lending Break has helped countless people overcome financial obstacles. We provide them with effective solutions for debt consolidation that accommodate their personal situations.</p>
                                <p>Debt can, without question, be quite crippling. We understand the difficulties that come with trying to break free from what seems like a bottomless financial hole.</p>
                                <p>There are a lot of options out there when it comes to debt consolidation. Finding out which one is right for you can be expensive, confusing, and time-consuming.</p>
                                <p>Are you in some sort of financial trouble? Are you trying to pay debts off as fast as you can? Do you need some assistance to achieve this? Lending Break will present a number of options to you that can help consolidate debt. This will help you pay off a portion of your debts all at once each month.</p>
                            </div>
                        </li>
                        <li>
                            <div className="text">
                                <p>Our solutions for debt consolidation allow you to restructure and reduce your existing debt, simplifying the process of paying it off as quickly as you can.</p>
                                <p>We understand how stressful things can get when you are trying to pay debt off one day after. The most frustrating thing is that those debts come with interest rates that are always going up.</p>
                                <p>Lending Break can simplify things for you so that you can break free from the financial troubles you currently find yourself in.</p>
                            </div>
                            <h3>Providing the best personal loans for debt consolidation</h3>
                        </li>
                    </ul>
                </div>
                <div className="third">
                    <h3>Our process for debt consolidation</h3>
                    <ul>
                        <li>
                            <div className="icon"></div>
                            <h4><b>Step 1:</b> Fill Out the Application</h4>
                            <div className="text">
                                <p>Lending Break understands how valuable time is. Nobody wants to spend their precious time searching for consolidation solutions and filling out endless applications online.</p>
                                <p>We have put together an easy and quick application that can be completed on our official website.</p>
                                <p>Our call center can be reached 24/7. If you have any questions about debt consolidation, our customer support team will be happy to answer them for you. Applying to our program will not impact the credit score you currently have. You have nothing to lose by seeing what our offers are.</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon"></div>
                            <h4><b>Step 2:</b> See Your Offers Instantly</h4>
                            <div className="text">
                                <p>After submitting your application for debt consolidation, it will be reviewed carefully, then matched with offers capable of resolving your financial situation efficiently.</p>
                                <p>Your time is too valuable to wait for consolidation offers to come to you. That's why we present you with offers right away. You'll be matched with some sort of solution for debt relief – perhaps even a consolidated loan. Whatever solution is presented to you will ultimately put you on the road to becoming debt-free.</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon"></div>
                            <h4><b>Step 3:</b> Choose the Best Solution</h4>
                            <div className="text">
                                <p>You'll be provided with various effective solutions for debt consolidation. Simply compare the advantages that come with each of them before choosing one you're comfortable with. Debt consolidation solutions that may take the form of a personal loan or some other type of debt relief entirely. The solution you'll be presented with will reduce your debt in the quickest amount of time possible.</p>
                                <p>We will be by your side when you go through these solutions. Each of them are capable of helping you achieve your financial goals and improving your existing financial standing.</p>
                            </div>
                        </li>
                        <li>
                            <div className="icon"></div>
                            <h4><b>Step 4:</b> Pay Off Your Debts</h4>
                            <div className="text">
                                <p>After submitting an application for debt consolidation, it will be reviewed carefully before being matched with suitable offers. Whichever one you end up going with, it will pull you out of the dire financial straits you currently find yourself in.</p>
                                <p>Nobody wants to wait for a consolidation offer to come to them. That's why we will present offers capable of helping you become debt-free right away. Whether you are matched with some kind of solution for debt relief or a consolidated loan, the choices at your disposal will be chosen on their efficiency and effectiveness.</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="alert">
                    <div className="coin"></div>
                    <div className="text">
                        <h3>Fill out a debt consolidation application for free!</h3>
                        <button onClick={()=>{this.props.history.push('/offers')}} className="standart">
                            see my offers
                        </button>
                    </div>
                    <div className="coin right"></div>
                </div>
            </main>
        );
    }
}
import React from 'react';
import axios from 'axios';
import IMask from 'imask';

import {CheckInput} from '../assets/plugins/functions';

import {host, token, contact_info} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class ContactUs extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            contact_info: {}
        }

        this.send_data = {
            name: '',
            email: '',
            phone: '',
            message: '',
        }
    }
    async componentDidMount(){
        var masked = IMask.createMask({
            mask: '+1 (000) 000 0000',
          });
        var info = await contact_info();

        info.telephone_masked = masked.resolve(info.telephone.toString());

        this.setState({contact_info: info});

        window.scrollTo(0, 0);
    }
    onChange(input){
        this.send_data[input.name] = new CheckInput().checkOnChange(this[input.name]);
    }
    onSubmit(e){
        e.preventDefault();

        let verify = new CheckInput().checkOnSubmit(this, this.send_data);

        if(verify){
            axios.post(host + '/api/mailsender/contactMail', this.send_data, {headers: {'authorization': token}})
            .then((res)  => {                
                if (res.status === 200) {
                    if(res.data.success){
                        Object.keys(this.send_data).forEach(key=>{
                            this.send_data[key] = '';
                            this[key].classList.add('right');
                            
                            if(this[key].querySelector('input')) 
                                this[key].querySelector('input').value = ''; 
                            else
                                this[key].querySelector('textarea').value = '';
                        })
                    }
                } else {
                  const error = new Error(res.data.error);
                  throw error;
                }
              })
            .catch(err => {
                console.error(err.response?err.response.data.error:err);
            }); 
        }
    }
    render(){
        return(
            <main id="contact_us">
                <MetaTags>
                    <title>For Debt Relief Services Contact Us | LendingBreak</title>
                    <meta name="description" content="We’re ready to take the plunge with you! We will find the lowest interest rate, a loan amount that meets your needs, and affordable and workable solutions. " />
                </MetaTags>

                {this.props.children}
                <div className="top">
                    <form className="standart" method="POST" onSubmit={e=>this.onSubmit(e)}>
                        <h2>Get in Touch</h2>
                        <label ref={el=>this.name = el}>
                            <input type="text" name="name" placeholder="Your name" onChange={(e)=>this.onChange(e.target)} required/>
                        </label>
                        <label ref={el=>this.phone = el}>
                            <input type="text" name="phone" placeholder="Your phone" onChange={(e)=>this.onChange(e.target)} required/>
                        </label>
                        <label ref={el=>this.email = el}>
                            <input type="text" name="email" placeholder="Your email" onChange={(e)=>this.onChange(e.target)} required/>
                        </label>
                        <label ref={el=>this.message = el}>
                            <textarea type="text" rows="5" name="message" placeholder="Your message" onChange={(e)=>this.onChange(e.target)} required></textarea>
                        </label>
                        <button className="standart" onClick={(e)=>this.onSubmit(e)}>SEND MESSAGE</button>
                    </form>
                    <div className="img">
                        <img src={require('../assets/img/illust_3.png')} alt="lower your cost of credit by consolidating your debt " />
                    </div>
                </div>
                <div className="contact">
                    <h1>Contact Us</h1>
                    <p>We’re ready to take the plunge with you!</p>
                    <ul>
                        <li>
                            <div className="title">Telephone</div>
                            <div className="text"><a href={"tel:+" + this.state.contact_info.telephone}>{this.state.contact_info.telephone_masked}</a></div>
                        </li>
                        {this.state.contact_info.address !== ''?<li>
                            <div className="title">address</div>
                            <div className="text">{this.state.contact_info.address}</div>
                        </li>:null}
                        {this.state.contact_info.email !== ''?<li>
                            <div className="title">email</div>
                            <div className="text"><a href={"mailto:" + this.state.contact_info.email}>{this.state.contact_info.email}</a></div>
                        </li>:null}
                    </ul>
                    <iframe src={this.state.contact_info.map_link} frameBorder="0" style={{border: "0"}} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                </div>
            </main>
        );
    }
}
import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import {CheckInput} from '../assets/plugins/functions';

import {token, host, months} from '../assets/options';

import MetaTags from 'react-meta-tags';

export default class Index extends React.Component{
    _isMounted = false;
    constructor(props){
        super(props);

        this.state = {
            reviews: []
        }

        this.currentReview = 0;
        this.reviewWidth = 34.5;
    }
    componentDidMount(){
        this._isMounted = true;

        this.getReviews();

        window.scrollTo(0, 0);
    }
    componentWillUnmount(){
        this._isMounted = false;
    }
    getReviews(){
        axios.post(host + '/api/reviews/', {sort: {createdAt: -1}}, {headers: {'authorization': token}})
        .then((res)  => {
            if (res.status === 200) {
                if(this._isMounted)
                    this.setState({
                        reviews: res.data.data,
                    })
            } else {
              const error = new Error(res.data.error);
              throw error;
            }
          })
        .catch(err => {
            console.error(err.response?err.response.data.error:err);
        }); 
    }
    onSubmit(e){
        e.preventDefault();

        let verify = new CheckInput().checkOnSubmit(this, {'personal_key': this.personal_key.firstChild.value});

        if(verify){
            console.log(this.personal_key.firstChild.value);
        }
    }
    nextReview(){
        if(this.currentReview === this.state.reviews.length - 3){
            this.nextArr.style.visibility = 'hidden';
        }

        this.prevArr.style.visibility = 'visible';

        this.currentReview++;

        this.container.style.transform = `translateX(-${this.currentReview*this.reviewWidth}vw)`
    }
    prevReview(){
        if(this.currentReview === 1){
            this.prevArr.style.visibility = 'hidden';
        }

        this.nextArr.style.visibility = 'visible';

        this.currentReview--;

        this.container.style.transform = `translateX(-${this.currentReview*this.reviewWidth}vw)`
    }
    loadReviews(){
        return this.state.reviews.map((review)=>{
            let date = new Date(review.date);
            let short_name = review.name.split(' ')[0][0] + review.name.split(' ')[1][0];
            return <div key={review._id} className="item">
                <div className="line">
                    <div className="name" style={{backgroundImage: review.img === ''?'none':`url(${host}/public/uploads/${review.img})`}}>{review.img === ''?short_name:''}</div>
                </div>
                <div className="info">
                    <div className="name">{review.name}</div>
                    <div className="date">{months[date.getMonth()]} {date.getDate()}, {date.getFullYear()}</div>
                    <p dangerouslySetInnerHTML={{__html: review.text}}></p>
                    <div className="origin">
                        <span>“</span>
                        <img className="logo" src={`${host}/public/uploads/${review.company.logo}`} alt=""/>
                        <div className="name">{review.company.name}</div>
                        <span>“</span>
                        <div className="stars">
                            <div className="star"></div>
                            <div className="star"></div>
                            <div className="star"></div>
                            <div className="star"></div>
                            <div className="star"></div>
                        </div>
                    </div>
                </div>
            </div>
        })
    }
    render(){
        return(
            <main id="index">
                <MetaTags>
                    <title>The Debt Consolidation Solutions That’s Right for You | LendingBreak</title>
                    <meta name="description" content="We present the best solutions for debt consolidation that can minimize your debt. It will lower the interest rates and make monthly payments easier to manage." />
                </MetaTags>

                {this.props.children}
                <div className="start">
                    <img className="person" src={require('../assets/img/person.png')} alt="debt consolidation solutions"/>
                    <img className="arrow" src={require('../assets/img/arrow.png')} alt="debt consolidation programs"/>
                    <div className="top">
                        <h1>The smartest way
                        to pay off your debt</h1>
                        <p>We find the debt consolidation solution that’s right for you. Instantly.</p>
                        <button onClick={()=>{this.props.history.push('/offers')}} className="standart red">
                            see my offers
                        </button>
                        <span>This will not affect your credit score</span>
                        <form method="POST" onSubmit={(e)=>this.onSubmit(e)}>
                            <p>I have a <span onClick={()=>{this.personal_key.firstChild.style.visibility = 'visible';}}>Personal Key</span> from a mail offer *</p>
                            <label ref={el=>this.personal_key = el}>
                                <input name="personal_key" placeholder="Enter your Personal Key" onChange={()=>{new CheckInput().checkOnChange(this.personal_key)}} required/>
                            </label>
                        </form>
                    </div>
                    <div className="bottom">
                        <ul>
                            <li><span>$0</span> Down</li>
                            <li><span>$0</span> Interest</li>
                            <li><span>Lower</span> Payments</li>
                            <li><span>$1,000’s</span> Saved</li>
                        </ul>
                        <p>* Your Personal Key located in the upper right-hand corner of your mail offer</p>
                    </div>
                </div>
                <div className="guide">
                    <h3>HOW IT WORKS</h3>
                    <div>
                        <div className="icon"></div>
                        <h4>Quick & Easy</h4>
                        <p>Our short online application
                            is quick and easy and it won’t affect your credit score.</p>
                    </div>
                    <div>
                        <div className="icon"></div>
                        <h4>See Your Offers Instantly</h4>
                        <p>We review and match your application with the best debt consolidation offers for you.</p>
                    </div>
                    <div>
                        <div className="icon"></div>
                        <h4>Accept the Best Solution</h4>
                        <p>Review and compare your offers and then accept the debt consolidation solution for you.</p>
                    </div>
                </div>
                <div className="consolidation">
                    <h3>Our debt consolidation solutions can get results for you too!</h3>
                    <div className="items">
                        <div className="arrow left" ref={el=>this.prevArr = el} onClick={()=>this.prevReview()}></div>
                        <div className="container">
                            <div ref={el=>this.container = el}>
                                {this.loadReviews()}
                            </div>
                        </div>
                        <div className="arrow right" style={{visibility: this.state.reviews.length >= 3?'visible':'hidden'}} ref={el=>this.nextArr = el} onClick={()=>this.nextReview()}></div>
                    </div>
                </div>
                <div className="whyUse">
                    <div className="top">
                        <div className="img">
                            <img src={require('../assets/img/illust_2.png')} alt="debt consolidation definition"/>
                        </div>
                        <div className="text">
                            <h3>Why Should I Consider Using Lending Break?</h3>
                            <span>We find the debt consolidayion solution that’s right for you</span>
                            <p>Our company has researched the best solutions for debt consolidation. You will be presented with solutions that can minimize your debt faster than you may expect. Such solutions will lower your interest rates and make your monthly payments a lot easier to manage.</p>
                            <button onClick={()=>{this.props.history.push('/offers')}} className="standart">
                                see my offers
                            </button>
                        </div>
                    </div>
                    <div className="types">
                        <h3>Types of debt we can help you pay off</h3>
                        <div className="accepted">
                            <h4>ACCEPTED DEBT TYPES</h4>
                            <ul>
                                <li>Credit Cards</li>
                                <li>Personal Loans</li>
                                <li>Medical Bills</li>
                                <li>Lines of Credit</li>
                                <li>Collections</li>
                                <li>Business Loans</li>
                                <li>Private Student Loans</li>
                            </ul>
                        </div>
                        <div className="notaccepted">
                            <h4>NOT ACCEPTED DEBT TYPES</h4>
                            <ul>
                                <li>Home Loans</li>
                                <li>Auto Loans</li>
                                <li>Government Loans</li>
                                <li>Taxes</li>
                                <li>Lawsuits</li>
                                <li>Utility Bills</li>
                                <li>Federal Student Loans</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="options">
                    <h3>Which debt consolidation solution is right for you?</h3>
                    <div className="container">
                        <div className="row">
                            <Link to="/debt-consolidation"><div className="text">
                                <h4>DEBT CONSOLIDATION</h4>
                                <p>Debt consolidation is a practical approach to repaying debt as fast as possible. You'll be able to reduce the interest you pay, too. This will be achieved by consolidating debt into either an updated loan, or through restructuring by way of a credit line. Your information will be carefully reviewed before being matched with consolidation solutions that correspond with your circumstances.</p>
                            </div></Link>
                            <div className="img">
                                <img src={require('../assets/img/coins.png')} alt="debt consolidation companies"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="img">
                                <img src={require('../assets/img/Coin.png')} alt="credit card debt consolidation"/>
                            </div>
                            <Link to="/personal-loan"><div className="text">
                                <h4>PERSONAL LOAN</h4>
                                <p>If your credit score is good, and your payments have been made on time, then unsecured personal loans are worth looking into. You'll be able to combine unsecured debt – including bills for credit cards – into a single monthly payment. You'll also receive a reduced interest rate, which can help you repay debt in the shortest amount of time possible.</p>
                            </div></Link>
                        </div>
                        <div className="row">
                            <Link to="/line-of-credit"><div className="text">
                                <h4>LINE OF CREDIT</h4>
                                <p>Debt restructuring using a credit line is an optimal approach if you have fallen behind on installment payments. It is also practical if you are only paying the minimum amount each month on your bills. Their purpose is to expand credit extensions, helping you combine collateralized debt directly into a single scheduled installment. You'll be able to repay what you owe with extended terms (up to two years) at a 0% interest rate. The money you pay will go towards reducing your debt instead of paying monthly interest.</p>
                            </div></Link>
                            <div className="img">
                                <img src={require('../assets/img/Growth.png')} alt="consolidate your debts" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alert">
                    <div className="coin"></div>
                    <div className="text">
                        <h3>The smartest way to pay off your debt is right here</h3>
                        <button onClick={()=>{this.props.history.push('/offers')}} className="standart">
                            see my offers
                        </button>
                    </div>
                    <div className="coin right"></div>
                </div>
            </main>
        );
    }
}
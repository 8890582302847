import React from 'react';

import MetaTags from 'react-meta-tags';

export default class Licensing extends React.Component{
    constructor(props){
        super(props);

        this.state = {
        }
    }
    
    componentDidMount () {
        window.scrollTo(0, 0);
    }

    render () {
        return (
            <main id="licensing">
                <MetaTags>
                    <title>Lending Break</title>
                    <meta name="description" content="" />
                </MetaTags>

                {this.props.children}
                <div>
                    <h1>Licensing</h1>
                    {/* <p>California DBO Finance Lenders License – #60DBO72167 with loans made under the California Financing Law</p>

                    <p>Florida OFRG Consumer Finance Company License – #CF9901215</p>

                    <p>Texas OCCC Regulated Lender License – #1700058365-162612</p>

                    <p>NMLS – #1622288</p> */}
                </div>
            </main>
        );
    }
}
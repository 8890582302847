import React from 'react';

export default class EnterpriseBusinessContinuity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main id="privacy_policy" className="privacy_policy--compliance">
                <div>
                    <h1>Enterprise Business Continuity</h1>

                    <div className="text">
                        <p>
                            Enterprise Business Continuity Policy aiming to restore critical business processes after disaster
                            in accordance with high level requirements.
                        </p>

                        <h4>Purpose</h4>
                        <p>
                            Our Enterprise Business Continuity procedures allow to provide continued service to our
                            customers and clients and to respond effectively to a disruptive event which may impact the
                            company, or interrupt normal operations.Through this Policy, Incursus establishes high level
                            requirements for the purposes of restoring critical business processes during disasters. The Policy
                            carries the Company’s objective to plan and reduce risk in case of possible disasters. Associated
                            standards, specifications, processes and procedures covey more details on business continuity
                            requirements.
                        </p>

                        <p>
                            A business continuity plan outlines procedures and instructions that the company must follow in
                            the face of disaster, whether fire, flood or cyberattack. Incurses created this strategy that gives
                            the business the best chance of surviving such an event.
                            The Policy is necessary to provide guidance on the Company’s compliance with applicable
                            regulations, meeting business continuity expectations of the Board of Directors and our
                            management team and effectively delivering on commitment to our customers.
                        </p>

                        <p>
                            The Policy objective is to make sure that Incursus can take proper measures to reduce risk of
                            essential impact on the ability to serve our customers and to continue critical business processes
                            as usual or with short interruptions after reasonably predictable disruption events.
                        </p>

                        <p>
                            The Policy effectiveness is highly dependent on such factors as preparedness levels, and comes
                            in accordance with the ability of people, technology, and processes to reduce the risk as well as
                            frequency and magnitude of disaster.
                        </p>

                        <h4>Scope</h4>
                        <p>
                            The Policy refers to all critical business processes as conveyed by the Business Impact Analysis.
                        </p>

                        <h4>Policy</h4>
                        <p>
                            To meet the enterprise business objectives and ensure continuity of its operations, Incursus
                            adopts well-defined plans and procedures, builds redundancy in teams and infrastructure, and manages a quick and efficient transition to the backup arrangement for business systems and
                            services. Incursus team takes full responsibility to develop business continuity plans, assist their
                            critical business processes and sub-processes, communication and implementation, to exercise
                            and maintain those plans by their process owners, staff and third party suppliers. The plans must
                            express the idea that those critical business processes can keep going on as usual or with short
                            interruptions after reasonably predictable disruption events.
                        </p>

                        <h4>Audience</h4>
                        <p>
                            All employees, contractors, consultants, third party suppliers and their employees and any other
                            individuals working on behalf of the Company.
                        </p>

                        <h4>Roles</h4>
                        <p>
                            The senior executive for each division is responsible to identify all critical business processes
                            under that executive’s control and assign a process owner responsible to ensure business
                            continuity for their critical processes. The following additional roles and responsibilities must be
                            in place:
                        </p>

                        <p>
                            Our senior executives are responsible for identifying and defining all the critical business
                            processes and measures under their supervision and assign tasks to process owners responsible to
                            ensure business continuity for those critical processes. Here are additional roles and
                            responsibilities:
                        </p>

                        <p>
                            <ul>
                                <li>
                                    Business Continuity Steering Committee – ensure enterprise support and accountability
                                    of business continuity
                                </li>

                                <li>
                                    Business Continuity Steering Committee Deputies – provide support and guidance to
                                    Steering Committee on enterprise recovery
                                </li>

                                <li>
                                    Business Continuity Coordinator - coordinates and leads business continuity planning,
                                    reporting and readiness for their critical processes
                                </li>

                                <li>
                                    Business Continuity Management Team – provide enterprise level oversight and
                                    governance of business continuity
                                </li>
                            </ul>
                        </p>

                        <h4>Enforcement</h4>
                        <p>
                            Business Continuity Management (BCM) is Incursus’s policy which sets the framework for the
                            organization to respond to internal and external threats and ensures the company’s preparedness,
                            resilience and ability to continue delivering its mandate when such threats occur.
                            Our Business Continuity Management (BCM) will monitor coherence with this policy as well as
                            report malfunctions to the executives responsible for certain critical processes. In case of failure
                            to uphold such obligations by third party suppliers to whom execution of a critical business
                            process for Incursus has been assigned, it can be considered a violation of this policy as a result
                            of which the contract can be breached and/or terminated. It’s necessary to comply with this policy on a move-onward basis. In case of contracts in effect prior to the policy implementation,
                            compliance is necessary at contract resumption.
                        </p>

                        <h2>Glossary</h2>

                        <h4>Business Continuity Management (BCM)</h4>
                        <p>
                            Business continuity management is a critical process. It ensures the company to maintain normal
                            business operations during a disaster with minimal disruption. It’s a comprehensive management
                            process conveying potential impacts that may harm an organization and providing a framework
                            to form business continuity and the capability to effectively respond protecting the interest of its
                            key stakeholders, reputation, brand and value-based activities.

                        </p>

                        <h4>Business Continuity Plans (BCPS)</h4>
                        <p>
                            Business continuity planning (BCP) is the process involved in creating a system of prevention
                            and recovery from potential threats to a company. The plan ensures that personnel and assets are
                            protected and are able to function quickly in the event of a disaster. It’s a part of business
                            continuity management that helps specifically in resuming certain tasks necessary to restore
                            critical processes or sub-processes during a disaster, addressing loss of building, equipment,
                            technology, human resources or 3rd party suppliers.
                        </p>

                        <h4>Business Impact Analysis (BIA) </h4>
                        <p>
                            An important part of developing a BCP is a business continuity impact analysis. It identifies the
                            effects of disruption of business functions and processes. It also uses the information to make
                            decisions about recovery priorities and strategies. Business Impact Analysis is a study that
                            defines the idea of the qualitative and quantitative risks to CSAA IG by time increment if a
                            business process or sub-process at a certain location can’t demonstrate the ability to implement
                            their critical tasks due to an unpredicted business interruption. Impacts may touch on various
                            aspects as legal/regulatory, financial or operational, brand, members or employee.
                        </p>

                        <h4>Critical Business Processes</h4>
                        <p>
                            Business impact analyses (BIA) should be conducted in order to establish appropriate response
                            priorities in business continuity plans. Identifying the implications of a sudden loss for each
                            business unit can determine process dependencies required to maintain operations of critical
                            business processes. Critical Business Processes are the critical operational and/or business
                            support processes and sub-processes that could not be intervened or unavailable for more than a
                            fixed or predetermined timeframe (&#x2039; 4weeks) without significantly putting the enterprise under
                            risk.
                        </p>

                        <h4>Disaster</h4>
                        <p>
                            A sudden, unpredicted force majeure event that may cause impermissible damage or loss: 1) An
                            event that brings forth an organization's capacity to provide critical processes, sub-processes or
                            services for some impermissible period of time; 2) An event where an organization’s
                            management calls on their recovery plans.
                        </p>

                        <h4>Exercise</h4>
                        <p>
                            Exercise is an activity with the main focus on people. It’s designed to accomplish business
                            continuity plans and give assessments of the individual and/or organization performance against
                            agreed standards or goals. Exercises can be announced or unannounced and are directed to
                            training and conditioning teammates and verifying the business continuity plan. Exercise results
                            disclose gaps and constraints of the plan and are used for the purposes to reform and revise the
                            Business Continuity Plans. The Company should regularly practice the continuity plans and
                            update them based on what was learned from the practice. If you don't test them, your continuity
                            plans quickly become outdated and most tabletop exercises are insufficient preparation for
                            addressing the issues you encounter in a hands on, real world test.
                        </p>

                        <h4>Process</h4>
                        <p>
                            A sequence of mutually dependent and interconnected procedures that at every phase, make use
                            of one or more resources (employee time, energy, machines, and money) to transform inputs
                            (data, material, parts, etc.) into outputs. These outputs can further act as inputs for the next phase
                            until achieving a given goal or result.
                        </p>

                        <h4>Risk</h4>
                        <p>
                            Potential for exposure to loss that can be determined by taking either qualitative or quantitative
                            measures.
                        </p>

                        <h4>Sub-Process</h4>
                        <p>
                            One or more tasks finalizing an essential part or stage of a process.
                        </p>
                    </div>
                </div>
            </main>
        );
    }
}
import React from 'react';

class CheckInput extends React.Component{
    checkOnChange=(label)=>{
        let input = label.querySelector('input')!==null?label.querySelector('input'):label.querySelector('textarea');
        let value;
        
        if((input.value === '' && input.required) || (input.value === ' ' && input.required)){
            label.classList.add('wrong');
            value = '';
        }else{
            label.classList.remove('wrong');
            value = input.value;
        }

        if(input.type === 'email'){
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.value)){
                label.classList.remove('wrong');
                label.classList.remove('notValued');
                value = input.value;
            }else if(input.value === '' || input.value === ' '){
                label.classList.add('wrong');
                label.classList.remove('notValued');
                value = '';
            }else{
                label.classList.add('wrong');
                label.classList.add('notValued');
                value = '';
            }
        }else if(input.type === 'checkbox'){
            value = input.checked;
        }

        return value;
    }
    checkOnSubmit=(e, obj)=>{
        let verify = true;

        Object.keys(obj).forEach(type => {
            let el = e[type].querySelector('input') !== null?e[type].querySelector('input'):e[type].querySelector('textarea');
            if(e[type]){
                if((obj[type] === '' && el.required) || (obj[type] === ' ' && el.required)){
                    e[type].classList.add('wrong');
                    verify = false;
                }else{
                    e[type].classList.remove('wrong');
                }
            }

            // if(type === 'phone_number'){
            //     if(obj[type].replace(/ /g, "").length < 11){
            //         e[type].classList.add('wrong');
            //         verify = false;
            //     }else{
            //         e[type].classList.remove('wrong');
            //     }
            // }else 
            if(type === 'birthday'){
                if(obj[type].replace(/ /g, "").length < 10){
                    e[type].classList.add('wrong');
                }else{
                    e[type].classList.remove('wrong');
                }
            }
        });

        return verify;
    }
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export{
    CheckInput,
    numberWithCommas,
}